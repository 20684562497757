import React from "react";

import format from "~/Utils/format";

const RateioTemplate = ({ rateio }) => (
  <div>
    <small className="text-muted">
      <span className="mr-1">{rateio.codigo_departamento}:</span>
      {rateio.departamento.descricao}
    </small>
    <small className="float-lg-right d-block d-lg-inline">
      <span className="mr-1">
        {rateio.codigo_grupo_empresa}/{rateio.codigo_conta}:
      </span>
      {rateio.conta.apelido_conta}
    </small>
    <p>
      {rateio.percentual}% - {format.money(rateio.valor)}
    </p>
    <hr />
  </div>
);

export default RateioTemplate;
