import styled from "styled-components";
import { Navbar } from "react-bootstrap";

export const MainNavbar = styled(Navbar)`
  background: #333;
  z-index: 1000;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  .navbar-brand {
    padding: 0;

    img {
      height: 30px;
    }
  }

  .dropdown-header {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 0.775rem;
  }

  .navbar-collapse {
    max-height: 80vh;
    overflow-y: auto;
    @media (min-width: 992px) {
      max-height: initial;
      overflow-y: initial;
    }
  }

  &.navbar-dark .navbar-nav .nav-link {
    color: #ffffffcf;
  }

  .dropdown-item,
  .nav-link {
    display: flex;
    font-size: 0.9rem;
    align-items: center;

    @media (min-width: 1440px) {
      font-size: 1rem;
    }

    img,
    svg {
      height: 20px;
      margin-right: 5px;
    }
  }
`;
