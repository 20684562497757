import React from "react";
import PropTypes from "prop-types";

import { TagContainer } from "./styles";

Tag.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.object,
};

export default function Tag({ text, icon }) {
  return (
    <TagContainer>
      {icon && <span>{icon}</span>}
      <span> {text}</span>
    </TagContainer>
  );
}
