import React, { useState, useEffect, useMemo } from "react";
import { Form, Modal, Button, Alert, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import Select from "react-select";
import Calendar from "react-calendar";
import MaskedInput from "react-text-mask";
import _ from "lodash";

import api from "~/Services/api";
import log from "~/Utils/Log";
import format from "~/Utils/format";
import masks from "~/Utils/Masks";

import GenericModal from "~/Components/GenericModal";
import { agendamentoValidationSchema } from "~/Validations";

const ModalAgendamento = ({
  show,
  servicos,
  locaisAtendimento,
  codigoUsuario,
  onCancel,
  onSuccess,
}) => {
  const [erro, setErro] = useState(null);
  const [horarios, setHorarios] = useState([]);
  const [buscandoHorarios, setBuscandoHorarios] = useState(false);
  const [erroBuscaHorarios, setErroBuscarHorarios] = useState(null);

  useEffect(() => {
    setErro(null);
    setErroBuscarHorarios(null);
    setHorarios([]);
  }, [show]);

  const servicosAgrupados = useMemo(() => {
    const grupoGeral = {
      label: "Geral",
      options: servicos
        .filter((s) => !s.sub_tipos || s.sub_tipos.length === 0)
        .map((s) => ({
          value: s.codigo.toString(),
          label: s.descricao,
          duracao_minutos: s.duracao_minutos,
        })),
    };

    const svcs = servicos
      .filter((s) => s.sub_tipos && s.sub_tipos.length > 0)
      .map((s) => ({
        label: s.descricao,
        options: s.sub_tipos.map((sub) => ({
          value: `${sub.codigo_tipo_atendimento};${sub.codigo}`,
          label: sub.descricao,
          duracao_minutos: sub.duracao_minutos,
        })),
      }));
    svcs.push(grupoGeral);

    return svcs;
  }, [servicos]);

  const locaisAtendimentoOptions = useMemo(() => {
    return locaisAtendimento.map((l) => ({
      value: l.codigo,
      label: l.descricao,
    }));
  }, [locaisAtendimento]);

  const matriz = locaisAtendimentoOptions.find((l) => l.value === -1);

  const criarAgendamento = async (values) => {
    try {
      let request = {
        data_inicial: values.horario_option.data_inicial,
        data_final: values.horario_option.data_final,
        codigo_usuario: values.horario_option.codigo_usuario,
        codigo_atendimento: values.codigo_tipo_atendimento,
        codigo_sub_tipo_atendimento: values.codigo_sub_tipo_atendimento,
        nome: values.nome,
        telefone: values.telefone.replace(/\D/g, ""),
        codigo_local_atendimento: values.local_atendimento.value,
      };
      log.debug(request);
      const agendamento = await api.criarAgendamento(request);
      onSuccess(agendamento);
    } catch (err) {
      setErro(err.message);
    }
  };

  return (
    <GenericModal
      show={show}
      onCancel={onCancel}
      outsideModalBody
      title="Novo Agendamento"
      size="lg"
    >
      <Formik
        onSubmit={criarAgendamento}
        initialValues={{
          nome: "",
          telefone: "",
          codigo_usuario_selecionado: codigoUsuario || 0,
          codigo_tipo_atendimento: "",
          codigo_sub_tipo_atendimento: "",
          option_select_servico: "",
          local_atendimento: matriz,
          horario: "",
          data: new Date(),
          horario_option: "",
        }}
        validationSchema={agendamentoValidationSchema}
      >
        {({
          handleSubmit,
          setFieldValue,
          setValues,
          values,
          isSubmitting,
          handleChange,
        }) => {
          log.debug(values);

          const buscarHorariosDisponiveis = async (
            dataInicial,
            dataFinal,
            codigoTipoAtendimento,
            codigoSubTipoAtendimento,
            codigoLocalAtendimento
          ) => {
            if (!codigoTipoAtendimento) return;
            setBuscandoHorarios(true);
            setHorarios([]);
            setErroBuscarHorarios(null);
            try {
              const request = {
                data_inicial: format.toLocalDateTimeString(dataInicial),
                data_final: format.toLocalDateTimeString(dataFinal),
                codigo_atendimento: codigoTipoAtendimento,
                codigo_sub_tipo_atendimento: codigoSubTipoAtendimento,
                codigo_local_atendimento: codigoLocalAtendimento,
                codigo_usuario: codigoUsuario,
              };
              log.debug(request);
              const horarios = await api.buscarDisponibilidadeServico(request);
              setHorarios(horarios);
            } catch (err) {
              console.error(err);
              setErroBuscarHorarios(
                err.message || "Falha ao buscar os horários disponíveis"
              );
            } finally {
              setBuscandoHorarios(false);
            }
          };

          const atendentesComHorarios = _.uniqBy(
            horarios.map((h) => ({
              value: h.codigo_usuario,
              label: h.usuario.nome,
            })),
            "value"
          );

          atendentesComHorarios.push({
            value: 0,
            label: "Aleatório",
          });

          log.debug("atendentesComHorarios", atendentesComHorarios);

          const optionsHorarios = _(
            horarios
              .filter(
                (h) =>
                  values.codigo_usuario_selecionado === 0 ||
                  h.codigo_usuario === values.codigo_usuario_selecionado
              )
              .map((h) => ({
                value: h.data_inicial,
                label: `${format.shortTime(
                  Date.parse(h.data_inicial)
                )} - ${format.shortTime(Date.parse(h.data_final))}`,
                data_inicial: h.data_inicial,
                data_final: h.data_final,
                codigo_usuario: h.codigo_usuario,
              }))
          )
            .shuffle()
            .orderBy("data_inicial")
            .uniqBy("data_inicial")
            .values();

          log.debug("optionHorarios", optionsHorarios);

          return (
            <>
              <Modal.Body>
                <Form.Group>
                  <Form.Label>Local</Form.Label>
                  <Select
                    options={locaisAtendimentoOptions}
                    isSearchable
                    value={values.local_atendimento}
                    onChange={(option) => {
                      setFieldValue("local_atendimento", option);
                      buscarHorariosDisponiveis(
                        values.data,
                        values.data,
                        values.codigo_tipo_atendimento,
                        values.codigo_sub_tipo_atendimento,
                        option.value
                      );
                    }}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Serviço</Form.Label>
                  <Select
                    options={servicosAgrupados}
                    placeholder="Selecione um serviço..."
                    isSearchable
                    onChange={(option) => {
                      log.debug(option);
                      if (!option) {
                        setValues({
                          ...values,
                          codigo_tipo_atendimento: "",
                          codigo_sub_tipo_atendimento: "",
                          codigo_select_servico: "",
                        });
                        return;
                      } else {
                        const codigos = option.value.split(";");
                        const cta = Number(codigos[0]);
                        const csta = Number(codigos[1]);
                        setValues({
                          ...values,
                          codigo_tipo_atendimento: cta,
                          codigo_sub_tipo_atendimento: csta,
                          option_select_servico: option,
                        });

                        buscarHorariosDisponiveis(
                          values.data,
                          values.data,
                          cta,
                          csta,
                          values.local_atendimento.value
                        );
                      }
                    }}
                    value={values.option_select_servico}
                  />
                </Form.Group>

                <Row>
                  <Col md={6}>
                    <Calendar
                      value={values.data}
                      onChange={(date) => {
                        setFieldValue("data", date);
                        buscarHorariosDisponiveis(
                          date,
                          date,
                          values.codigo_tipo_atendimento,
                          values.codigo_sub_tipo_atendimento,
                          values.local_atendimento.value
                        );
                      }}
                      locale="pt-BR"
                      minDate={new Date()}
                      className="mx-auto pb-2"
                    />
                  </Col>
                  <Col md={6}>
                    {values.option_select_servico &&
                      values.option_select_servico.duracao_minutos && (
                        <Alert variant="info">
                          Este serviço dura{" "}
                          {values.option_select_servico.duracao_minutos} minutos
                        </Alert>
                      )}
                    {!values.codigo_tipo_atendimento && (
                      <p>
                        Selecione um serviço para exibir os horários disponíveis
                      </p>
                    )}
                    {erroBuscaHorarios && (
                      <p className="text-danger">{erroBuscaHorarios}</p>
                    )}
                    {buscandoHorarios && <p>Buscando horários...</p>}
                    {!erroBuscaHorarios &&
                      values.codigo_tipo_atendimento &&
                      !buscandoHorarios &&
                      horarios.length === 0 && (
                        <p>
                          Nenhum horário disponível, tente configurar as escalas
                          deste serviço.
                        </p>
                      )}

                    {horarios.length > 0 && (
                      <>
                        <Form.Group>
                          <Form.Label>Atendente</Form.Label>
                          <Select
                            options={atendentesComHorarios}
                            placeholder="Selecione um atendente..."
                            onChange={(option) => {
                              setFieldValue(
                                "codigo_usuario_selecionado",
                                option.value
                              );
                            }}
                            value={atendentesComHorarios.find(
                              (a) =>
                                a.value === values.codigo_usuario_selecionado
                            )}
                            isDisabled={
                              !values.codigo_tipo_atendimento || !!codigoUsuario
                            }
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>Horário</Form.Label>
                          <Select
                            options={optionsHorarios}
                            placeholder="Selecione um horário..."
                            onChange={(option) => {
                              setFieldValue("horario_option", option);
                            }}
                            value={values.horario_option}
                            isDisabled={!values.codigo_tipo_atendimento}
                          />
                        </Form.Group>
                      </>
                    )}
                  </Col>
                </Row>

                <Form.Group>
                  <Form.Label>Nome</Form.Label>
                  <Form.Control name="nome" onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Telefone</Form.Label>
                  <MaskedInput
                    type="cel"
                    className="form-control"
                    name="telefone"
                    onChange={handleChange}
                    value={values.telefone}
                    mask={masks.celular}
                    placeholder="(00) 00000-0000"
                  />
                </Form.Group>

                {erro && <Alert variant="danger">{erro}</Alert>}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="outline-primary"
                  onClick={onCancel}
                  disabled={isSubmitting}
                >
                  Cancelar
                </Button>
                <Button
                  variant="primary"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Aguarde..." : "Salvar"}
                </Button>
              </Modal.Footer>
            </>
          );
        }}
      </Formik>
    </GenericModal>
  );
};

export default ModalAgendamento;
