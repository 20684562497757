import React from "react";

const Switch = ({
  onChange,
  checked,
  label,
  switchClassName,
  labelClassName,
}) => {
  return (
    <>
      <label className={`mr-1 ${labelClassName}`}>{label}</label>
      <label className={`switch ${switchClassName}`}>
        <input type="checkbox" onChange={onChange} checked={checked} />
        <span className="slider round"></span>
      </label>
    </>
  );
};

export default Switch;
