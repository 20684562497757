import tinycolor from "tinycolor2";

class colors {
  danger = "#dc3545";
  dangerLight = tinycolor(this.danger).lighten(42).toHexString();
  warning = "#ffc107";
  success = "#28a745";
  successLight = tinycolor(this.success).lighten(55).toHexString();
  primary = "#007bff";
  primaryLight = tinycolor(this.primary).lighten(45).toHexString();
  muted = "#6c757d";
  mutedLight = tinycolor(this.muted).lighten(52).toHexString();
}

export default new colors();
