import React from "react";
import colors from "~/Utils/Styling/colors";

import EnvironmentTagContainer from "./styles";

export default function EnvironmentTag() {
  const isLocal = process.env.REACT_APP_ENV === "local";
  const isProd = process.env.REACT_APP_ENV === "prod";
  const isQA = process.env.REACT_APP_ENV === "hml";
  const isProdDB = process.env.REACT_APP_DB === "prod";
  const isHmlDB = process.env.REACT_APP_DB === "hml";
  if (isProdDB && !isLocal && !isQA) return null;
  return (
    <EnvironmentTagContainer
      color={
        isHmlDB ? colors.warning : isProdDB ? colors.danger : colors.success
      }
    >
      <p>
        Ambiente de
        <strong>
          {isQA ? " QA" : isProd ? " PRODUÇÃO" : " desenvolvimento"}
        </strong>
        &nbsp;Banco de
        <strong>
          {isHmlDB
            ? " treinamento"
            : isProdDB
            ? " PRODUÇÃO"
            : " desenvolvimento"}
        </strong>
      </p>
    </EnvironmentTagContainer>
  );
}
