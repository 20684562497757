import React from "react";
import { Form } from "react-bootstrap";

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  return (
    <Form.Control
      as="input"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder="Filtrar..."
    />
  );
}

export default DefaultColumnFilter;
