import styled from "styled-components";
import { Modal } from "react-bootstrap";

export const ColoredModal = styled(Modal).attrs(props => ({
  color: props.color
}))`
  .modal-content {
  }
  .modal-header {
    color: ${props => props.color};
  }
`;
