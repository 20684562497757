import React from "react";
import { Form, Button } from "react-bootstrap";

const StringFilter = ({ filter, onFilterChanged, onFilterCleared }) => {
  return (
    <Form.Group className="d-flex align-items-baseline mb-0">
      <Form.Label>Filtrar:&nbsp;</Form.Label>
      <Form.Control value={filter} onChange={onFilterChanged}></Form.Control>
      <Button variant="outline-primary" onClick={onFilterCleared}>
        Limpar
      </Button>
    </Form.Group>
  );
};

export default StringFilter;
