import styled from "styled-components";

export const ResponsiveTableContainer = styled.div`
  div.table-responsive {
    @media (max-width: 576px) {
      width: 85vw !important;
    }
  }
  table > tbody > tr > td {
    vertical-align: middle;
  }
`;
