class format {
  money(value) {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  }
  date(value) {
    return new Intl.DateTimeFormat("pt-BR", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    }).format(new Date(value));
  }
  datetime(value) {
    return new Intl.DateTimeFormat("pt-BR", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    }).format(new Date(value));
  }
  shortTime(dateValue) {
    return new Intl.DateTimeFormat("pt-BR", {
      hour: "numeric",
      minute: "numeric",
      locale: "pt-BR",
      timeZone: "America/Sao_Paulo",
    }).format(dateValue);
  }
  toLocalDateTimeString(dateValue) {
    return dateValue.toISOString().replace("Z", "");
  }
}

export default new format();
