import axios from "axios";
import AuthStorage from "~/Utils/AuthStorage";

const httpService = {
  onUnauthorized: (action) => {
    axios.interceptors.response.use(
      (response) => {
        //simply return the response if there is no error
        return response;
      },
      (error) => {
        console.error(error);
        if (!error) Promise.reject("Erro desconhecido");
        //in this case we only care about unauthorized errors
        if (
          error.response &&
          (error.response.status === 403 || error.response.status === 401)
        ) {
          action();
        }
        return Promise.reject(error.response);
      }
    );
  },
  fetch: ({ url, data, headers, ...rest }, useBaseUrl = true) => {
    const accessToken = AuthStorage.getToken();
    const authorization = accessToken ? `Bearer ${accessToken}` : null;

    headers = {
      ...headers,
      Authorization: authorization,
    };

    url = useBaseUrl ? process.env.REACT_APP_API_URL + url : url;

    return axios({
      url,
      data,
      headers,
      ...rest,
    })
      .then((res) => res.data)
      .catch((res) => {
        console.error(res);
        if (res) throw res.data;
        throw new Error("no response");
      });
  },
  post: (url, data, headers) =>
    httpService.fetch({ url, data, headers, method: "post" }),
  get: (url, usarApiBase) => httpService.fetch({ url }, usarApiBase),
  put: (url, data, headers) =>
    httpService.fetch({ url, data, headers, method: "put" }),
};

export default httpService;
