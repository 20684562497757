import React from "react";

export const DescriptiveCodeLabel = ({ title, code, text, responsive }) => (
  <DescriptiveLabel title={title} responsive={responsive}>
    <span className="mr-1">{code}:</span>
    <span>{text}</span>
  </DescriptiveLabel>
);

export const DescriptiveLabel = ({ title, children, responsive, ...rest }) => (
  <div {...rest} className="row">
    <small
      className={`text-muted col-12 ${
        responsive ? "col-sm-2 text-sm-right" : ""
      }`}
    >
      {title}
    </small>
    <div className={`col-12 ${responsive ? "col-sm-10" : ""}`}>{children}</div>
  </div>
);
