class Log {
  debug(message, ...optionalParameters) {
    if ("production" !== process.env.NODE_ENV)
      console.debug(message, optionalParameters);
  }

  trace(message) {
    if ("production" !== process.env.NODE_ENV) console.log(message);
  }

  log(message) {
    if ("production" !== process.env.NODE_ENV) console.log(message);
  }
}

export default new Log();
