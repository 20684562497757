import React from "react";
import { FaSpinner } from "react-icons/fa";

import { LoadingContainer } from "./styles";

const Loading = ({ label = "Carregando..." }) => (
  <LoadingContainer>
    <FaSpinner className="spinner" /> {label}
  </LoadingContainer>
);

export default Loading;
