import React from "react";

import { TIPOS_CHAVE } from "~/constants";

export default function ChaveId({ chave, className }) {
  const tipoChave = chave.tipo === TIPOS_CHAVE.Despesa ? "DESP" : "PROJ";
  return (
    <small className={`text-muted ${className}`}>
      #{chave.codigo_grupo_empresa}-{chave.codigo_conta_portador}-{chave.codigo}
      <span
        className={`font-weight-bold ml-1 text-${
          chave.tipo === TIPOS_CHAVE.Despesa ? "danger" : "primary"
        }`}
      >
        {tipoChave}
      </span>
    </small>
  );
}
