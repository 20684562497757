const param = {
  get(props, name) {
    return new URLSearchParams(props.location.search).get(name);
  },
  has(props, name) {
    const value = this.get(props, name);
    return !!value;
  }
};

export default param;
