import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import api from "~/Services/api";
import debounce from "lodash/debounce";

const SelectUsuario = ({ onChange, value, loadFunction, ...rest }) => {
  const [carregandoUsuarios, setCarregandoUsuarios] = useState(false);

  const carregarUsuarios = async (inputValue, callback) => {
    setCarregandoUsuarios(true);
    const usuarios = loadFunction
      ? await loadFunction(inputValue)
      : await api.buscarUsuariosPorTermo(inputValue);
    const options = usuarios.map((u) => ({
      value: u.codigo,
      label: u.nome,
    }));
    setCarregandoUsuarios(false);
    callback(options);
  };

  // useEffect(() => setUsuarios([]), [])
  return (
    <AsyncSelect
      isLoading={carregandoUsuarios}
      loadingMessage={() => "Carregando..."}
      noOptionsMessage={() => "Digite o nome de um usuário para pesquisar"}
      placeholder="Pesquisar usuários..."
      loadOptions={debounce(carregarUsuarios, 500)}
      onChange={onChange}
      blurInputOnSelect
      closeMenuOnSelect
      cacheOptions
      value={value}
      {...rest}
    />
  );
};

export default SelectUsuario;
