import React from "react";
import { Row, Col, Form, Table } from "react-bootstrap";
import Paginator from "../Paginator";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";

const DefaultTableRenderer = ({ tableInstance, title, size = "lg" }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page, // Instead of using 'rows', we'll use page, which has only the rows for the active page
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
  } = tableInstance;
  return (
    <>
      <Row>
        <Col>
          <h5>{title}</h5>
        </Col>
        <Col sm={4}>
          <Form.Control
            as="select"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 50, 100, 200].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Mostrar {pageSize}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Row>
      <Table
        {...getTableProps()}
        striped
        bordered
        hover
        responsive
        className="mt-2"
        size="sm"
      >
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <div>
                        {
                          // Render the header
                          column.render("Header")
                        }
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <MdKeyboardArrowDown />
                            ) : (
                              <MdKeyboardArrowUp />
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                      <div onClick={(e) => e.stopPropagation()}>
                        {column.canFilter ? column.render("Filter") : null}
                      </div>
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {
            // Loop over the table rows
            page.map((row) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr {...row.getRowProps()}>
                  {
                    // Loop over the rows cells
                    row.cells.map((cell) => {
                      // Apply the cell props
                      return (
                        <td {...cell.getCellProps()}>
                          {
                            // Render the cell contents
                            cell.render("Cell")
                          }
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </Table>

      <Paginator
        onGotoPage={gotoPage}
        pageIndex={pageIndex}
        onPreviousPage={previousPage}
        onNextPage={nextPage}
        totalPages={pageOptions.length}
      />
    </>
  );
};

export default DefaultTableRenderer;
