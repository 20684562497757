import styled from "styled-components";
import colors from "~/Utils/Styling/colors";
import { min } from "~/Utils/Styling";

export const ResumoChavesContainer = styled.div`
  .card {
    margin-top: 10px;
    ${min.sm`
      position: fixed;
      max-width: 300px;
	  `};
  }
  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  button {
    width: 100%;
  }

  footer button:first-child {
    margin-right: 20px;
  }

  footer {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
  }

  main {
    max-height: 250px;
    overflow-y: auto;
  }
`;

export const EmptyWarning = styled.div`
  color: ${colors.muted};
  text-align: center;
`;
