import React, { useState } from "react";
import { GithubPicker } from "react-color";

import { ColorPickerWrapper, ColorDisplay, ColorSwatch } from "./styles";

const ColorPicker = ({ hex, onChange, disabled }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  return (
    <ColorPickerWrapper>
      <ColorSwatch
        onClick={() => {
          if (disabled) return;
          setDisplayColorPicker((prev) => !prev);
        }}
        disabled={disabled}
      >
        <ColorDisplay hex={hex} />
      </ColorSwatch>
      {displayColorPicker && (
        <div className="cp-popover">
          <div onClick={() => setDisplayColorPicker(false)} />
          <GithubPicker
            color={{ hex: hex }}
            onChangeComplete={(newColor) => {
              onChange(newColor.hex);
              setDisplayColorPicker(false);
            }}
          />
        </div>
      )}
    </ColorPickerWrapper>
  );
};

export default ColorPicker;
