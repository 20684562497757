import React from "react";
import { Button, OverlayTrigger, Popover, ButtonGroup } from "react-bootstrap";

const ConfirmButton = ({
  confirmTitle,
  confirmText,
  onConfirm,
  children,
  className,
  variant = "link",
  disabled = false,
  size = "md",
}) => {
  const fechar = () => {
    document.body.click(); // clica "do lado de fora"
  };
  return (
    <OverlayTrigger
      placement="top"
      trigger="click"
      rootClose
      overlay={
        <Popover>
          <Popover.Title>{confirmTitle}</Popover.Title>
          <Popover.Content>
            <p>{confirmText}</p>
            <ButtonGroup className="w-100">
              <Button variant="outline-secondary" onClick={fechar}>
                Não
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  onConfirm();
                  fechar();
                }}
              >
                Sim
              </Button>
            </ButtonGroup>
          </Popover.Content>
        </Popover>
      }
    >
      <Button
        variant={variant}
        className={className}
        disabled={disabled}
        size={size}
      >
        {children}
      </Button>
    </OverlayTrigger>
  );
};

export default ConfirmButton;
