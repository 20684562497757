const MASK_CPF = [
  /\d/,
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
];
const MASK_CNPJ = [
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  "/",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
];
const MASK_CEL = [
  "(",
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
const MASK_CEL_9 = [
  "(",
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const masks = {
  phone: (phone) => {
    return MASK_CEL;
  },
  celular: (phone) => {
    if (!phone) {
      return MASK_CEL;
    }

    let numbers = phone.replace(/\s|-|_|\.|\(|\)/g, "");
    let numberLength = 0;
    if (numbers) {
      numberLength = numbers.length;
    }

    return numberLength > 10 ? MASK_CEL_9 : MASK_CEL;
  },
  cpf_cnpj: (str) => {
    if (!str) {
      return MASK_CPF;
    }

    let numbers = str.replace(/[^0-9]+/g, "");

    if (!numbers) {
      return MASK_CPF;
    }

    return numbers.length > 11 ? MASK_CNPJ : MASK_CPF;
  },
};

export default masks;
