import styled from "styled-components";

export const TagContainer = styled.div`
  padding: 8px;
  background: #ddd;
  text-transform: uppercase;
  font-size: 12px;
  border-radius: 3px;
  margin-bottom: auto;
  margin-top: auto;
  display: inline;
  margin-right: 4px;
`;
