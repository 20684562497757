import React from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import EasyEdit from "react-easy-edit";

import Switch from "../../../Switch";

const DisplayServico = ({ servico, atualizarServico, onEscalaClicked }) => {
  const isSubTipo = !!servico.codigo_tipo_atendimento;
  const configuravel = !servico.temSubTipos;
  if (servico.descricao === "2º Via de Boleto") console.log(servico);

  return (
    <>
      <Row>
        <Col xs={12} sm={4}>
          {isSubTipo ? (
            <span className="ml-4">
              {servico.codigo} - {servico.descricao}
            </span>
          ) : (
            <strong>
              {servico.codigo} - {servico.descricao}
            </strong>
          )}
        </Col>
        {configuravel && (
          <>
            <Col>
              <Form
                inline
                onSubmit={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <Switch
                  checked={servico.agendavel}
                  onChange={(e) => {
                    atualizarServico(
                      isSubTipo
                        ? servico.codigo_tipo_atendimento
                        : servico.codigo,
                      isSubTipo ? servico.codigo : null,
                      e.target.checked,
                      servico.duracao_minutos
                    );
                  }}
                  label="Agendável:"
                  labelClassName="text-muted"
                  switchClassName="mr-4"
                />
                <Form.Group className="d-flex align-items-baseline">
                  <Form.Label className="mr-1 text-muted">Duração: </Form.Label>
                  <div
                    className={`easy-edit-small ${
                      !servico.agendavel ? "disabled" : ""
                    }`}
                  >
                    <EasyEdit
                      type="number"
                      onSave={(value) => {
                        atualizarServico(
                          isSubTipo
                            ? servico.codigo_tipo_atendimento
                            : servico.codigo,
                          isSubTipo ? servico.codigo : null,
                          servico.agendavel,
                          value
                        );
                      }}
                      saveButtonLabel={<FaCheck />}
                      saveButtonStyle="btn btn-outline-primary"
                      cancelButtonLabel={<MdCancel />}
                      cancelButtonStyle="btn btn-outline-danger"
                      placeholder={
                        servico.agendavel
                          ? "Clique para editar"
                          : "Não agendável"
                      }
                      value={servico.duracao_minutos}
                      attributes={{ className: "form-control w-50" }}
                      allowEdit={servico.agendavel}
                    />
                  </div>
                  {servico.duracao_minutos && (
                    <span className="ml-1">minutos</span>
                  )}
                </Form.Group>
              </Form>
            </Col>
            <Col sm={2}>
              <Button
                variant="link"
                onClick={onEscalaClicked}
                className="float-right"
                disabled={!servico.agendavel}
              >
                Escalas
              </Button>
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

export default DisplayServico;
