import React, { Component, Fragment } from "react";
import { Media } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import PropTypes from "prop-types";

import PDFThumb from "~/Utils/PDFThumb";

export default class CardDocumento extends Component {
  static propTypes = {
    documento: PropTypes.object.isRequired,
    onRemove: PropTypes.func.isRequired,
    enableTrash: PropTypes.bool,
    //onSelectImage: PropTypes.func.isRequired,
    onClickDocumento: PropTypes.func.isRequired,
    onThumbCreated: PropTypes.func.isRequired,
  };

  static defaultProps = {
    enableTrash: true,
  };

  async componentDidMount() {
    const { documento } = this.props;
    let Thumb = null;
    if (documento.url && !documento.Thumb) {
      try {
        Thumb = await PDFThumb.create(documento.url);
        this.props.onThumbCreated(Thumb, documento);
      } catch (error) {
        this.props.onThumbCreated(null, documento);
        console.error(error);
      }
    }
  }

  render() {
    const {
      onRemove,
      //onSelectImage,
      onClickDocumento,
      enableTrash,
      documento,
    } = this.props;

    return (
      <Media
        as="li"
        className={`CardDocumento ${
          !documento.url && "CardDocumento--vazio"
        } align-items-center mb-2`}
      >
        {documento.url ? (
          <Fragment>
            {documento.thumbCreated && !documento.Thumb && (
              <div className="CardDocumento__img align-self-center mr-3 text-center d-flex align-items-center">
                <a
                  href={documento.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-100"
                >
                  <small>Abrir PDF</small>
                </a>
              </div>
            )}
            {documento.Thumb && (
              <div
                className="CardDocumento__img align-self-center mr-3"
                style={{ backgroundImage: `url(${documento.Thumb})` }}
                onClick={(e) => onClickDocumento(e, documento)}
              />
            )}
            <Media.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div className="CardDocumento__descricao">
                  {documento.descricao}
                  {!documento.hasOwnProperty("Thumb") && (
                    <small className="d-block">Carregando documento...</small>
                  )}
                </div>
                {documento.hasOwnProperty("Thumb") && enableTrash && (
                  <FaTrash
                    className="CardDocumento__btn-remove"
                    onClick={() => onRemove(documento)}
                  />
                )}
              </div>
            </Media.Body>
          </Fragment>
        ) : (
          <p>SOMEDAY: upload documento :)</p>
        )}
      </Media>
    );
  }
}
