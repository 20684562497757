import styled from "styled-components";
import { max } from "~/Utils/Styling";

export const LoginContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #333;
  .card {
    width: 100%;
    margin: auto;
    max-width: 400px;
    height: auto;
    text-align: center;
    ${max.xs`
      border-radius: 0;
      height: 100%;
      padding-top: 3.5em;
	  `};
  }

  .input-group {
    margin: 10px 0px;
  }

  button {
    width: 100%;
  }
`;
