import React, { useState, useEffect, useMemo } from "react";
import { Form, Modal, Button, Alert } from "react-bootstrap";
import { Formik } from "formik";
import Select from "react-select";
import log from "~/Utils/Log";
import api from "~/Services/api";
import { diasDaSemana } from "~/constants";
import GenericModal from "~/Components/GenericModal";
//import SelectUsuario from "~/Components/SelectUsuario";

const ModalEscalasServico = ({ servico, atendentes, onCancel, onSuccess }) => {
  const [erro, setErro] = useState(null);

  useEffect(() => {
    setErro(null);
  }, [servico]);

  const mapEscalas = (escalas, diaSemana) => {
    return escalas
      .filter((e) => e.dia_semana === diaSemana)
      .map((e) => ({
        value: e.codigo_usuario,
        label: e.usuario.nome,
      }));
  };

  const configurarEscalasServico = async (values) => {
    setErro(null);
    log.debug("configurando escalas " + JSON.stringify(values));
    try {
      const request = {
        codigo_atendimento: servico.codigo_tipo_atendimento || servico.codigo,
        codigo_sub_tipo_atendimento: servico.codigo_tipo_atendimento
          ? servico.codigo
          : null,
        escalas: values.escalas,
      };
      log.debug("Enviando configuracao de escalas: " + JSON.stringify(request));
      await api.atualizarEscalasServico(request);
      onSuccess(servico, values.escalas);
    } catch (err) {
      const erro = err.message;
      setErro(erro);
      console.error(erro);
    }
  };

  const atendentesOptions = useMemo(
    () =>
      atendentes.map((a) => ({
        value: a.codigo,
        label: a.nome,
        diasSemana: [
          ...new Set(
            a.horarios.reduce(
              (fullArray, h) => [...fullArray, ...h.dias_semana],
              []
            )
          ),
        ],
      })),
    [atendentes]
  );

  log.debug(atendentesOptions);

  if (!servico) return null;

  return (
    <GenericModal
      show={!!servico}
      onCancel={onCancel}
      outsideModalBody
      title={servico.descricao}
    >
      <Formik
        onSubmit={configurarEscalasServico}
        initialValues={{
          escalas: servico.escalas,
        }}
      >
        {({ handleSubmit, setFieldValue, setValues, values, isSubmitting }) => {
          log.debug(values.escalas);
          const addEscala = (options, diaSemana) => {
            log.debug("Adicionando na escala " + JSON.stringify(options));
            const escalas = values.escalas.filter(
              (e) => e.dia_semana !== diaSemana
            );
            if (options) {
              escalas.push(
                ...options.map((option) => ({
                  codigo_usuario: option.value,
                  usuario: {
                    nome: option.label,
                  },
                  dia_semana: diaSemana,
                }))
              );
            }
            setFieldValue("escalas", escalas);
          };

          const copiarEscala = (de, para) => {
            const escalasDe = values.escalas.filter((e) => e.dia_semana === de);

            const escalas = values.escalas.filter((e) => e.dia_semana !== para);
            escalas.push(
              ...escalasDe.map((escala) => ({
                codigo_usuario: escala.codigo_usuario,
                usuario: {
                  nome: escala.usuario.nome,
                },
                dia_semana: para,
              }))
            );

            setFieldValue("escalas", escalas);
          };

          return (
            <>
              <Modal.Body>
                {servico && (
                  <>
                    <Form.Group>
                      <Form.Label>Segunda</Form.Label>
                      <Select
                        isMulti
                        options={atendentesOptions.filter((o) =>
                          o.diasSemana.includes(diasDaSemana.Segunda)
                        )}
                        isSearchable
                        value={mapEscalas(values.escalas, diasDaSemana.Segunda)}
                        onChange={(option) => {
                          addEscala(option, diasDaSemana.Segunda);
                        }}
                        isDisabled={isSubmitting}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="d-flex justify-content-between">
                        Terça
                        <Button
                          variant="link"
                          className="p-0"
                          onClick={() =>
                            copiarEscala(
                              diasDaSemana.Segunda,
                              diasDaSemana.Terca
                            )
                          }
                        >
                          Copiar do de cima
                        </Button>
                      </Form.Label>
                      <Select
                        isMulti
                        options={atendentesOptions.filter((o) =>
                          o.diasSemana.includes(diasDaSemana.Terca)
                        )}
                        isSearchable
                        value={mapEscalas(values.escalas, diasDaSemana.Terca)}
                        onChange={(option) => {
                          addEscala(option, diasDaSemana.Terca);
                        }}
                        isDisabled={isSubmitting}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="d-flex justify-content-between">
                        Quarta
                        <Button
                          variant="link"
                          className="p-0"
                          onClick={() =>
                            copiarEscala(
                              diasDaSemana.Terca,
                              diasDaSemana.Quarta
                            )
                          }
                        >
                          Copiar do de cima
                        </Button>
                      </Form.Label>
                      <Select
                        isMulti
                        options={atendentesOptions.filter((o) =>
                          o.diasSemana.includes(diasDaSemana.Quarta)
                        )}
                        isSearchable
                        value={mapEscalas(values.escalas, diasDaSemana.Quarta)}
                        onChange={(option) => {
                          addEscala(option, diasDaSemana.Quarta);
                        }}
                        isDisabled={isSubmitting}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="d-flex justify-content-between">
                        Quinta
                        <Button
                          variant="link"
                          className="p-0"
                          onClick={() =>
                            copiarEscala(
                              diasDaSemana.Quarta,
                              diasDaSemana.Quinta
                            )
                          }
                        >
                          Copiar do de cima
                        </Button>
                      </Form.Label>
                      <Select
                        isMulti
                        options={atendentesOptions.filter((o) =>
                          o.diasSemana.includes(diasDaSemana.Quinta)
                        )}
                        isSearchable
                        value={mapEscalas(values.escalas, diasDaSemana.Quinta)}
                        onChange={(option) => {
                          addEscala(option, diasDaSemana.Quinta);
                        }}
                        isDisabled={isSubmitting}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="d-flex justify-content-between">
                        Sexta
                        <Button
                          variant="link"
                          className="p-0"
                          onClick={() =>
                            copiarEscala(
                              diasDaSemana.Quinta,
                              diasDaSemana.Sexta
                            )
                          }
                        >
                          Copiar do de cima
                        </Button>
                      </Form.Label>
                      <Select
                        isMulti
                        options={atendentesOptions.filter((o) =>
                          o.diasSemana.includes(diasDaSemana.Sexta)
                        )}
                        isSearchable
                        value={mapEscalas(values.escalas, diasDaSemana.Sexta)}
                        onChange={(option) => {
                          addEscala(option, diasDaSemana.Sexta);
                        }}
                        isDisabled={isSubmitting}
                      />
                    </Form.Group>

                    {/* <Form.Group>
                      <Form.Label className="d-flex justify-content-between">
                        Sábado
                        <Button
                          variant="link"
                          className="p-0"
                          onClick={() =>
                            copiarEscala(
                              diasDaSemana.Sexta,
                              diasDaSemana.Sabado
                            )
                          }
                        >
                          Copiar do de cima
                        </Button>
                      </Form.Label>
                      <SelectUsuario
                        isMulti
                        value={mapEscalas(values.escalas, diasDaSemana.Sabado)}
                        onChange={(option) => {
                          addEscala(option, diasDaSemana.Sabado);
                        }}
                        isDisabled={isSubmitting}
                      />
                    </Form.Group> */}
                  </>
                )}
                {erro && <Alert variant="danger">{erro}</Alert>}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="outline-primary"
                  onClick={onCancel}
                  disabled={isSubmitting}
                >
                  Cancelar
                </Button>
                <Button
                  variant="primary"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Aguarde..." : "Salvar"}
                </Button>
              </Modal.Footer>
            </>
          );
        }}
      </Formik>
    </GenericModal>
  );
};

export default ModalEscalasServico;
