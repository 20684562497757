import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

import CardDocumento from "~/Components/CardDocumento";

import { ChaveDetailsContainer } from "./styles";

const ChaveDetails = ({ chave, onShowInfoRequested }) => {
  const [digitalizacoes, setDigitalizacoes] = useState([]);
  useEffect(() => {
    setDigitalizacoes(chave.digitalizacoes);
  }, [chave]);
  function toggleOcorrencias(e) {
    e.stopPropagation();
    onShowInfoRequested(chave, "ocorrencias");
  }

  function toggleRateios(e) {
    e.stopPropagation();
    onShowInfoRequested(chave, "rateios");
  }

  function toggleRetencoes(e) {
    e.stopPropagation();
    onShowInfoRequested(chave, "retencoes");
  }

  function toggleDigitalizacao(e, digitalizacao) {
    e.stopPropagation();
    onShowInfoRequested(chave, "digitalizacao", digitalizacao);
  }

  function onThumbCreated(thumb, index) {
    const list = digitalizacoes.filter((d, i) => {
      if (index === i) {
        d.Thumb = thumb;
        d.thumbCreated = true;
      }
      return d;
    });
    setDigitalizacoes(list);
  }

  const observacoes = chave.observacoes.filter((texto) => !!texto);

  return (
    <>
      <ChaveDetailsContainer className="p-2">
        {chave.comprovante_mesmo_dia && <small>Comprovante no mesmo dia</small>}
        {chave.numero_documento && (
          <p className="numero-documento">
            <small>Nº. Doc.:</small> {chave.numero_documento}
          </p>
        )}
        {observacoes.some(() => true) && (
          <div>
            <p>
              <strong>
                Observações <span>({observacoes.length})</span>
              </strong>
            </p>
            {observacoes.map((texto, index) => (
              <small className="d-block" key={index}>
                {texto}
              </small>
            ))}
            <hr />
          </div>
        )}

        {digitalizacoes.some(() => true) && (
          <div>
            <p>
              <strong>
                Digitalizações <span>({digitalizacoes.length})</span>
              </strong>
            </p>
            <div className="CamposDocumentos d-flex card-documento-grande flex-wrap">
              {digitalizacoes.map((digitalizacao, index) => (
                <CardDocumento
                  key={index}
                  enableTrash={false}
                  documento={digitalizacao}
                  onRemove={() => {}}
                  onSelectImage={() => {}}
                  onClickDocumento={(e) =>
                    toggleDigitalizacao(e, digitalizacao)
                  }
                  onThumbCreated={(thumb, doc) => onThumbCreated(thumb, index)}
                />
              ))}
            </div>
            <hr />
          </div>
        )}
        <div className="d-flex flex-column flex-sm-row justify-content-sm-end w-100 options-container">
          {chave.retencoes.length > 0 && (
            <Button className="mb-1" onClick={toggleRetencoes}>
              Retencões <span>({chave.retencoes.length})</span>
            </Button>
          )}
          {chave.rateios.length > 0 && (
            <Button className="mb-1" onClick={toggleRateios}>
              Rateios <span>({chave.rateios.length})</span>
            </Button>
          )}
          {chave.ocorrencias.length > 0 && (
            <Button className="mb-1" onClick={toggleOcorrencias}>
              Ocorrências <span>({chave.ocorrencias.length})</span>
            </Button>
          )}
        </div>
      </ChaveDetailsContainer>
    </>
  );
};

export default ChaveDetails;
