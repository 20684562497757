import React from "react";
import { Redirect, Route } from "react-router-dom";

import AuthStorage from "~/Utils/AuthStorage";

import PageContainer from "~/Containers/PageContainer";

export default function PrivateRoute({
  component: Component,
  authorizationEvaluator,
  ...rest
}) {
  const toRender = (props) => {
    if (AuthStorage.isLogged()) {
      if (authorizationEvaluator) {
        const isAuthorized = authorizationEvaluator();
        if (!isAuthorized) return <Redirect to="/inicio" />;
      }

      return (
        <PageContainer>
          <Component {...props} />
        </PageContainer>
      );
    }

    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: props.location },
        }}
      />
    );
  };

  return <Route {...rest} render={toRender} />;
}
