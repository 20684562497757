import styled from "styled-components";
import { min } from "~/Utils/Styling";

//// className="p-2"
export const ChaveDetailsContainer = styled.div`
  border: 1px dashed var(--primary);
  background: var(--light);

  .numero-documento {
    float: right;
  }

  .options-container .btn:not(:last-child) {
    ${min.sm`
      margin-right: 10px;

	  `};
  }
`;
