import React from "react";
import { Button, Card } from "react-bootstrap";

import { TIPOS_CHAVE } from "~/constants";
import format from "~/Utils/format";
import ChaveId from "../ChaveId";
import { ResumoChavesContainer, EmptyWarning } from "./styles";
import { FaTimesCircle } from "react-icons/fa";

export default function ResumoChaves({
  chavesSelecionadas,
  onAprovar,
  onReprovar,
  onClickSelecionarTodos,
  onLimparSelecao,
}) {
  const valorTotal = chavesSelecionadas.reduce(
    (total, curr) => total + curr.valor_liquido,
    0
  );
  const temChaves = chavesSelecionadas.length > 0;
  return (
    <ResumoChavesContainer>
      <Card>
        <Card.Body>
          <header>
            {temChaves && (
              <>
                <div>
                  <small>
                    <strong>{chavesSelecionadas.length}</strong> chaves
                    selecionadas
                  </small>
                </div>
                <small className="text-muted">Total</small>
                <h5>{format.money(valorTotal)}</h5>
                <hr />
              </>
            )}
            {!temChaves && (
              <EmptyWarning>
                <p>
                  Nenhuma chave selecionada. Clique na chave para selecioná-la.
                  <Button variant="primary" onClick={onClickSelecionarTodos}>
                    Selecionar Todas
                  </Button>
                </p>
                <p>
                  Selecione uma ou mais chaves para aprová-las ou reprová-las.
                </p>
              </EmptyWarning>
            )}
          </header>
          <main>
            {chavesSelecionadas.map((chave) => (
              <div
                key={chave.codigo}
                className={`px-2 mb-2 border position-relative arrow-bottom-left-${
                  chave.tipo === TIPOS_CHAVE.Despesa ? "danger" : "primary"
                }`}
              >
                <ChaveId chave={chave}></ChaveId>
                <p>{format.money(chave.valor_liquido)}</p>
              </div>
            ))}
          </main>
          {temChaves && (
            <div className="mt-2">
              <Button
                variant="secondary"
                onClick={onLimparSelecao}
                className="d-flex align-items-center justify-content-center"
                disabled={!temChaves}
              >
                <FaTimesCircle className="mr-1" /> Limpar Seleção
              </Button>
            </div>
          )}
          <footer className="mt-2">
            <Button variant="danger" onClick={onReprovar} disabled={!temChaves}>
              REPROVAR
            </Button>
            <Button variant="success" onClick={onAprovar} disabled={!temChaves}>
              APROVAR
            </Button>
          </footer>
        </Card.Body>
      </Card>
    </ResumoChavesContainer>
  );
}
