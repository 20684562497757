import styled from "styled-components";

export const MainContainer = styled.div`
  padding-top: 53px;
  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  height: auto;
  min-height: calc(100vh - 60px);
`;

export const MainContent = styled.div.attrs({
  className: `ml-sm-auto`
})`
  flex: 1;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  @media (min-width: 992px) {
    width: ${props => (props.isFull ? "100%" : "80%")};
  }
`;
