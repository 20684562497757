import React from "react";
import { IoIosArrowUp } from "react-icons/io";

import { ScrollToTopButtonContainer } from "./styles";

//https://codepen.io/Tofutseng/pen/MdYdod
class ScrollToTopButton extends React.Component {
  constructor() {
    super();

    this.state = {
      intervalId: 0
    };
  }

  scrollStep = () => {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
  };

  scrollToTop = () => {
    let intervalId = setInterval(
      this.scrollStep.bind(this),
      this.props.delayInMs
    );
    this.setState({ intervalId: intervalId });
  };

  render() {
    return (
      <ScrollToTopButtonContainer
        title="Voltar ao topo"
        className={`scroll ${this.props.className}`}
        onClick={this.scrollToTop}
      >
        <span className="arrow-up">
          <IoIosArrowUp />
        </span>
      </ScrollToTopButtonContainer>
    );
  }
}

export default ScrollToTopButton;
