import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";
import { FaKeyboard } from "react-icons/fa";

import { TIPOS_CHAVE } from "~/constants";
import format from "~/Utils/format";
import colors from "~/Utils/Styling/colors";
import ChaveId from "../ChaveId";
import ChaveDetails from "../ChaveDetails";
import { DescriptiveCodeLabel, DescriptiveLabel } from "~/Components/Labels";

function CardChave({ chave, onCardClick, onShowInfoRequested }) {
  const [showDetails, setShowDetails] = useState(false);

  function toggleDetailsView(e) {
    e.stopPropagation();
    setShowDetails(!showDetails);
  }

  const bgClass =
    chave.nivel === 0
      ? colors.mutedLight
      : chave.selected
      ? colors.primaryLight
      : "white";

  return (
    <Card
      style={{ background: bgClass }}
      className={`my-2 cursor-pointer arrow-bottom-left-${
        chave.tipo === TIPOS_CHAVE.Despesa ? "danger" : "primary"
      }`}
    >
      <Card.Body onClick={onCardClick} name="card-chave">
        {chave.nivel === 0 &&
          chave.codigo_usuario_digitacao !== chave.codigo_usuario_caixa && (
            <div className="rounded alert-danger text-center w-100 mb-2 p-1 p-sm-0">
              <small>
                <strong>
                  Esta chave não pode ser selecionada pois necessita de
                  correção. Utilize o GES para corrigir esta chave
                </strong>
              </small>
            </div>
          )}
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-between">
              <ChaveId chave={chave} />
              <small
                className="text-muted text-sm-right"
                title="Data de criação"
              >
                {format.date(chave.data)}
              </small>
            </div>
            <div className="row mb-2">
              <div className="col-12">
                <div className="d-flex justify-content-between">
                  <small>
                    Possui digitalizações:
                    {chave.digitalizacoes.length === 0 ? (
                      <strong className="text-danger"> Não</strong>
                    ) : (
                      " Sim"
                    )}
                  </small>
                  {chave.digitalizacao_pendente && (
                    <small className="badge badge-primary">
                      Digitalização pendente
                    </small>
                  )}
                  <div>
                    <small>Tipo documento:</small>
                    <small className="ml-1" title="Tipo documento">
                      {chave.tipo_documento}
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-6 order-last order-sm-first d-flex">
                <DescriptiveLabel title="Líquido">
                  <h5>{format.money(chave.valor_liquido)}</h5>
                </DescriptiveLabel>
                <DescriptiveLabel title="Bruto">
                  <h5>{format.money(chave.valor_bruto)}</h5>
                </DescriptiveLabel>
              </div>
              <div className="col-12 col-sm-6 text-sm-right">
                <small
                  className="d-block"
                  title={chave.usuario_digitacao.nome_usuario}
                >
                  <FaKeyboard className="mr-1" />
                  {chave.usuario_digitacao.nome}
                </small>
                <div className="text-danger">
                  <small>Vencimento</small>
                  <small className="ml-1" title="Data de vencimento">
                    {format.date(chave.data_vencimento)}
                  </small>
                </div>
              </div>
            </div>

            <DescriptiveCodeLabel
              title="Departamento"
              responsive
              code={chave.codigo_departamento}
              text={chave.departamento.descricao}
            />
            <DescriptiveCodeLabel
              title="Fornecedor"
              responsive
              code={chave.codigo_conta_fornecedor}
              text={chave.fornecedor.apelido_conta}
            />
            <DescriptiveCodeLabel
              title="Conta"
              responsive
              code={chave.codigo_conta_portador}
              text={chave.conta_portador.apelido_conta}
            />
          </div>
        </div>
        <div className="row">
          <div className="float-right text-muted d-flex flex-sm-column">
            <Button
              variant="link"
              className="float-right"
              onClick={toggleDetailsView}
            >
              Detalhes
            </Button>
          </div>
        </div>
        {showDetails && (
          <ChaveDetails
            chave={chave}
            onShowInfoRequested={onShowInfoRequested}
          />
        )}
      </Card.Body>
    </Card>
  );
}

export default React.memo(CardChave);
