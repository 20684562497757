import React from "react";
import Select from "react-select";

function FiltrosChaves({
  setFiltros,
  contas,
  usuarios,
  fornecedores,
}) {
  const handleChange = (name) => (selectedOption) => {
    setFiltros((prevFiltros) => ({
      ...prevFiltros,
      [name]: selectedOption ? selectedOption.value : "",
    }));
  };

  const formatOptions = (options) =>
    options.map((option) => ({
      value: option.value,
      label: option.label,
    }));

  return (
    <div className="mb-2">
      <div className="row">
        <div className="col-md-12">
          <Select
            className="basic-single"
            classNamePrefix="select"
            placeholder="Selecione uma conta"
            name="conta"
            options={formatOptions(contas)}
            onChange={handleChange("conta")}
            isClearable
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mt-2">
          <Select
            className="basic-single"
            classNamePrefix="select"
            placeholder="Selecione um usuário digitador"
            name="usuarioDigitador"
            options={formatOptions(usuarios)}
            onChange={handleChange("usuarioDigitador")}
            isClearable
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mt-2">
          <Select
            className="basic-single"
            classNamePrefix="select"
            placeholder="Selecione um fornecedor"
            name="fornecedor"
            options={formatOptions(fornecedores)}
            onChange={handleChange("fornecedor")}
            isClearable
          />
        </div>
      </div>
    </div>
  );
}

export default FiltrosChaves;
