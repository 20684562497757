import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import App from "~/Containers/App";
import EnvironmentTag from "~/Components/EnvironmentTag";
import AuthStorage from "~/Utils/AuthStorage";
import { caracteristicas } from "~/constants";

import Login from "~/Pages/Login";
import CaixaAprovacao from "~/Pages/Negocios/CaixaAprovacao";
import ConfiguracaoAtendentes from "~/Pages/Atendimento/ConfiguracaoAtendentes";
import ConfiguracaoServicos from "~/Pages/Atendimento/ConfiguracaoServicos";
import Agendamento from "~/Pages/Atendimento/Agendamento";
//import RelatorioAgendamento from "~/Pages/Atendimento/RelatorioAgendamento";
import Inicio from "~/Pages/Inicio";

const Routes = () => (
  <App>
    <EnvironmentTag />
    <Switch>
      <Route exact path="/">
        <Redirect to="/inicio" />
      </Route>
      <Route path="/login" component={Login} />
      <PrivateRoute
        path="/negocios/caixa-aprovacao"
        component={CaixaAprovacao}
      />
      <PrivateRoute
        path="/atendimento/configuracao/atendentes"
        component={ConfiguracaoAtendentes}
        authorizationEvaluator={() =>
          AuthStorage.temCaracteristica(caracteristicas.ConfiguraAtendimento)
        }
      />
      <PrivateRoute
        path="/atendimento/configuracao/servicos"
        component={ConfiguracaoServicos}
        authorizationEvaluator={() =>
          AuthStorage.temCaracteristica(caracteristicas.ConfiguraAtendimento)
        }
      />
      <PrivateRoute
        exact
        path="/atendimento/agendamento"
        component={Agendamento}
      />
      {/* <PrivateRoute
        exact
        path="/atendimento/agendamento/relatorio"
        component={RelatorioAgendamento}
      /> */}
      <PrivateRoute path="/inicio" component={Inicio} />
    </Switch>
  </App>
);

export default Routes;
