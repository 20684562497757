import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Button, Form, Card, InputGroup, Alert } from "react-bootstrap";
import { Formik } from "formik";
import { FaUser, FaLock } from "react-icons/fa";
import BeatLoader from "react-spinners/BeatLoader";

import api from "~/Services/api";
import AuthStorage from "~/Utils/AuthStorage";
import param from "~/Utils/ParamParser";
import { LoginContainer } from "./styles";
import { loginValidationSchema } from "~/Validations";

import Logo from "~/Components/Logo";

const Login = (props) => {
  const [isLogout, setIsLogout] = useState(param.has(props, "logout"));
  const [isExpired, setIsExpired] = useState(param.has(props, "expired"));
  const [userLogged, setUserLogged] = useState(AuthStorage.isLogged());
  const [loginError, setLoginError] = useState(null);
  const [activityMessage, setActivityMessage] = useState(null);

  const history = useHistory();

  useEffect(() => {
    if (isLogout) {
      setTimeout(() => {
        setIsLogout(false);
      }, 3000);
    }
    if (isExpired) {
      setTimeout(() => {
        setIsExpired(false);
      }, 3000);
    }
  });

  async function doLogin(values, actions) {
    setUserLogged(false);
    setLoginError(null);

    try {
      setActivityMessage("Autenticando");
      const user = await api.login({ ...values });
      AuthStorage.setUser(user);
      setActivityMessage("Buscando configurações");
      const claims = await api.getClaims();
      AuthStorage.setCaracteristicas(claims.caracteristicas);
      setActivityMessage("Verificando caixa");
      const temChave = await api.verificarTemChaveNaCaixa();
      history.push(temChave ? "/negocios/caixa-aprovacao" : "/");
    } catch (error) {
      console.error(error);
      const errorMessage =
        error.error_description ||
        error.message ||
        "Usuário ou senha inválidos";
      setLoginError(errorMessage);
      actions.setSubmitting(false);
    } finally {
      setActivityMessage(null);
    }
  }

  if (userLogged) {
    return <Redirect to="/" />;
  }

  return (
    <LoginContainer>
      <Card className="border-0">
        <Card.Body>
          <Logo size={50} center />
          {isLogout && (
            <div className="alert alert-info">Você desconectou!</div>
          )}
          {isExpired && (
            <div className="alert alert-info">Sua sessão expirou</div>
          )}

          <p className="text-secondary">
            Informe o seu usuário e senha para acessar
          </p>
          <Formik
            validationSchema={loginValidationSchema}
            onSubmit={doLogin}
            initialValues={{
              username: "",
              password: "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              touched,
              errors,
              isSubmitting,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <FaUser />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="text"
                    placeholder="Usuário"
                    name="username"
                    autoComplete="username"
                    value={values.username}
                    onChange={handleChange}
                    disabled={isSubmitting}
                    isInvalid={touched.username && errors.username}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.username}
                  </Form.Control.Feedback>
                </InputGroup>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <FaLock />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="password"
                    placeholder="Senha"
                    name="password"
                    autoComplete="current-password"
                    value={values.password}
                    onChange={handleChange}
                    disabled={isSubmitting}
                    isInvalid={touched.password && errors.password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </InputGroup>
                <Button type="submit" variant="primary" disabled={isSubmitting}>
                  {isSubmitting ? (
                    <div className="d-flex justify-content-center">
                      <span className="mr-2">{activityMessage}</span>
                      <BeatLoader size={8} color="#CDCDCD" />
                    </div>
                  ) : (
                    "Entrar"
                  )}
                </Button>

                {loginError && <Alert variant="danger">{loginError}</Alert>}
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </LoginContainer>
  );
};

export default Login;
