import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

GenericModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  titleChildren: PropTypes.object,
  content: PropTypes.any,
  outsideModalBody: PropTypes.bool,
};

export default function GenericModal({
  show,
  onCancel,
  title,
  titleChildren,
  content,
  outsideModalBody,
  children,
  size = "md",
}) {
  return (
    <Modal show={show} onHide={onCancel} centered size={size}>
      <Modal.Header closeButton>
        <Modal.Title className="mr-auto">{title}</Modal.Title>
        <div className="align-self-center ml-auto">{titleChildren}</div>
      </Modal.Header>
      {outsideModalBody ? (
        content || children
      ) : (
        <Modal.Body>{content}</Modal.Body>
      )}
    </Modal>
  );
}
