import React from "react";
import PropTypes from "prop-types";
import { Navbar } from "react-bootstrap";

Header.propTypes = {
  title: PropTypes.string.isRequired,
};

export default function Header({ children, title }) {
  return (
    <Navbar
      className="bg-white"
      expand="lg"
      style={{ borderBottom: "1px solid #d9d9d9" }}
    >
      <h5 className="mr-auto navbar-text mb-0 text-body">{title}</h5>
      <div>{children}</div>
    </Navbar>
  );
}
