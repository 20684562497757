import React from "react";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import AuthStorage from "../../Utils/AuthStorage";
import api from "../../Services/api";

import { AppContainer } from "./styles.js";

export default function App({ children }) {
  const history = useHistory();

  api.onUnauthorized(() => {
    toast.dismiss(0);
    AuthStorage.clear();
    history.push("/login?expired=true");
  });

  return (
    <AppContainer>
      <ToastContainer
        position={toast.POSITION.TOP_RIGHT}
        toastClassName="mt-5"
        newestOnTop={true}
      />
      {children}
    </AppContainer>
  );
}
