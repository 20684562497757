import React, { useState, useEffect, useRef, useCallback } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import TUICalendar from "@toast-ui/react-calendar";
import { GoPrimitiveDot } from "react-icons/go";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import tinycolor from "tinycolor2";
import Select from "react-select";
import { useHistory } from "react-router-dom";

import api from "~/Services/api";
import log from "~/Utils/Log";
import AuthStorage from "~/Utils/AuthStorage";
import { caracteristicas } from "~/constants";

import calendarTheme from "./theme";
import Header from "~/Components/Header";
import Loading from "~/Components/Loading";
import ModalAgendamento from "~/Components/Atendimento/Agendamento/ModalAgendamento";

const Agendamento = () => {
  const cal = useRef(null, []);
  const [busyCounter, setBusyCounter] = useState(0);
  const [showModalAgendamento, setShowModalAgendamento] = useState(false);
  const [agendamentos, setAgendamentos] = useState([]);
  const [calendarios, setCalendarios] = useState([]);
  const [servicos, setServicos] = useState([]);
  const [locaisAtendimento, setLocaisAtendimento] = useState([]);
  const history = useHistory();

  const realizaAgendamento = AuthStorage.temCaracteristica(
    caracteristicas.RealizaAgendamento
  );
  const codigoUsuario = AuthStorage.getUser().usuario;
  log.debug(realizaAgendamento, codigoUsuario);

  useEffect(() => {
    (async () => {
      try {
        setBusyCounter((prev) => prev + 1);
        const servicos = await api.buscarServicos(true);
        setServicos(servicos);
      } catch (err) {
        console.error(err);
        toast.error("Falha ao buscar os serviços");
      } finally {
        setBusyCounter((prev) => prev - 1);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setBusyCounter((prev) => prev + 1);
        let locaisAtendimento = await api.buscarLocaisAtendimento();
        locaisAtendimento = locaisAtendimento.filter((l) => !l.matriz);
        locaisAtendimento.push({
          codigo: -1,
          descricao: "Matriz",
        });

        setLocaisAtendimento(locaisAtendimento);
      } catch (err) {
        console.error(err);
        toast.error("Falha ao buscar os locais de atendimento");
      } finally {
        setBusyCounter((prev) => prev - 1);
      }
    })();
  }, []);

  const buscarAgendamentos = useCallback(async () => {
    try {
      //Não marcar como busy para não dar "loading" no calendário e renderizar errado
      // setBusyCounter((prev) => prev + 1);
      let request = {
        codigo_usuario: realizaAgendamento ? null : codigoUsuario,
        data_inicial: getCalInstance().getDateRangeStart()._date,
        data_final: getCalInstance().getDateRangeEnd()._date,
      };
      log.debug(request);
      const agendamentos = await api.buscarAgendamentos(request);
      const schedules = agendamentos.map((a) => toCalendarSchedule(a));

      setAgendamentos(schedules);
    } catch (err) {
      console.error(err);
      toast.error("Falha ao buscar agendamentos");
    } finally {
      // setBusyCounter((prev) => prev - 1);
    }
  }, [codigoUsuario]);

  useEffect(() => {
    buscarAgendamentos();
  }, [codigoUsuario, buscarAgendamentos, history]);

  useEffect(() => {
    (async () => {
      try {
        setBusyCounter((prev) => prev + 1);
        const calendarios = await api.buscarCalendarios(codigoUsuario);
        log.debug(calendarios);
        setCalendarios(
          calendarios.map((c) => ({
            id: c.codigo,
            name: c.nome,
            bgColor: c.cor_calendario,
            borderColor: tinycolor(c.cor_calendario).darken(10).toHexString(),
          }))
        );
      } catch (err) {
        console.error(err);
        toast.error("Falha ao buscar calendarios");
      } finally {
        setBusyCounter((prev) => prev - 1);
      }
    })();
  }, [codigoUsuario]);

  // const start = new Date();
  // const end = new Date(new Date().setMinutes(start.getMinutes() + 30));

  const getCalInstance = () => (cal.current ? cal.current.getInstance() : null);
  console.log(getCalInstance());

  const toCalendarSchedule = (agendamento) => {
    return {
      calendarId: agendamento.codigo_usuario,
      category: "time",
      isVisible: true,
      title: agendamento.atendimento.descricao,
      id: agendamento.codigo,
      body: `${agendamento.nome} - ${agendamento.telefone_formatado}`,
      start: agendamento.data_inicial,
      end: agendamento.data_final,
    };
  };

  const mainContent = () => {
    return (
      <Row noGutters>
        <Col sm={2}>
          <Button
            onClick={() => setShowModalAgendamento(true)}
            className="w-100"
            disabled={!!busyCounter}
          >
            Agendar
          </Button>
          <div className="m-1">
            {calendarios
              .filter((c) => realizaAgendamento || c.id === codigoUsuario)
              .map((c) => (
                <div key={c.id}>
                  <GoPrimitiveDot color={c.bgColor} fontSize={12} />
                  <small>{c.name}</small>
                </div>
              ))}
          </div>
        </Col>

        <Col>
          {!!busyCounter && <Loading />}
          {!busyCounter && renderCalendar()}
        </Col>
      </Row>
    );
  };

  const renderCalendar = () => {
    return (
      <>
        <div className="d-flex">
          <Select
            onChange={(option) => getCalInstance().changeView(option.value)}
            className="w-25"
            defaultValue={{ value: "week", label: "Semana" }}
            isSearchable={false}
            options={[
              { value: "day", label: "Dia" },
              { value: "week", label: "Semana" },
              { value: "month", label: "Mês" },
            ]}
          />

          <Button
            variant="light"
            onClick={() => {
              getCalInstance().today();
              buscarAgendamentos();
            }}
          >
            Hoje
          </Button>
          <Button
            variant="light"
            onClick={() => {
              getCalInstance().prev();
              buscarAgendamentos();
            }}
          >
            <IoIosArrowBack />
          </Button>
          <Button
            variant="light"
            onClick={() => {
              getCalInstance().next();
              buscarAgendamentos();
            }}
          >
            <IoIosArrowForward />
          </Button>
        </div>
        <TUICalendar
          ref={cal}
          taskView={false}
          scheduleView={["time"]}
          defaultView="month"
          usageStatistics={false}
          calendars={calendarios}
          schedules={agendamentos}
          useDetailPopup
          isReadOnly
          month={{
            daynames: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
            startDayOfWeek: 1,
          }}
          week={{
            daynames: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
            hourStart: 8,
            hourEnd: 18,
            startDayOfWeek: 1,
          }}
          theme={calendarTheme}
          template={{
            // traduz o "X more" para "mais X" da visão mensal quando o dia tem vários eventos
            monthGridHeaderExceed: (hiddenSchedules) => {
              return (
                '<span class="tui-full-calendar-weekday-grid-more-schedules">mais ' +
                hiddenSchedules +
                "</span>"
              );
            },
            // popupDetailDate: (isAllDay, start, end) => {
            //   const startDate = moment(start.toLocalTime());
            //   const endDate = moment(end.toLocalTime());
            //   var isSameDate = startDate.isSame(end);
            //   var endFormat = (isSameDate ? "" : "DD/MM/YYYY ") + "hh:mm a";

            //   if (isAllDay) {
            //     return (
            //       startDate.format("DD/MM/YYYY") +
            //       (isSameDate ? "" : " - " + endDate.format("DD/MM/YYYY"))
            //     );
            //   }
            //   return (
            //     startDate.format("DD/MM/YYYY hh:mm a") +
            //     " - " +
            //     endDate.format(endFormat)
            //   );
            // },
          }}
        />
      </>
    );
  };

  return (
    <>
      <Header title={codigoUsuario ? "Minha Agenda" : "Agendamento"}>
        <Button onClick={() => buscarAgendamentos()}>Atualizar</Button>
      </Header>

      {mainContent()}

      <ModalAgendamento
        show={showModalAgendamento}
        onCancel={() => setShowModalAgendamento(false)}
        servicos={servicos}
        locaisAtendimento={locaisAtendimento}
        onSuccess={(agendamento) => {
          setAgendamentos((prev) => [...prev, toCalendarSchedule(agendamento)]);
          setShowModalAgendamento(false);
          toast.success("Agendamento criado com sucesso.");
        }}
        codigoUsuario={realizaAgendamento ? null : codigoUsuario}
      />
    </>
  );
};

export default Agendamento;
