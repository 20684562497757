import React, { useState, useEffect, useCallback } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import api from "~/Services/api";
import Header from "~/Components/Header";
import OrderBy from "~/Components/OrderBy";
import Loading from "~/Components/Loading";
import ScrollToTopButton from "~/Components/ScrollToTopButton";
import GenericModal from "~/Components/GenericModal";
import ResumoChaves from "~/Components/Negocios/CaixaAprovacao/ResumoChaves";
import CardChave from "~/Components/Negocios/CaixaAprovacao/CardChave";
import ModalAprovarReprovar from "~/Components/Negocios/CaixaAprovacao/ModalAprovarReprovar";
import ChaveId from "~/Components/Negocios/CaixaAprovacao/ChaveId";
import { orderingOptions, blankView, modalViews } from "./options";
import FiltrosChaves from "./FiltrosChaves";
import { FaFilter, FaTrashAlt } from "react-icons/fa";

export default function CaixaAprovacao() {
  const [chaves, setChaves] = useState([]);
  const [chavesSelecionadas, setChavesSelecionadas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [showInfo, setShowInfo] = useState(false);
  const [modalView, setModalView] = useState(blankView);

  const [ordering, setOrdering] = useState(orderingOptions[0]);

  const [filtros, setFiltros] = useState({
    conta: "",
    usuarioDigitador: "",
    fornecedor: "",
  });
  const [appliedFilters, setAppliedFilters] = useState({
    conta: "",
    usuarioDigitador: "",
    fornecedor: "",
  });

  const [showModalFiltros, setShowModalFiltros] = useState(false);
  const [showModalAprovarReprovar, setShowModalAprovarReprovar] =
    useState(false);
  const [shouldAprovar, setShouldAprovar] = useState(false);

  async function buscarChavesAprovacao() {
    setIsLoading(true);
    try {
      const response = await api.buscarChavesAprovacao();
      setChaves(response.sort(ordering.func));
      setChavesSelecionadas([]);
    } catch (error) {
      if (error.status !== 401) toast.error("Falha ao buscar as chaves");
    } finally {
      setIsLoading(false);
    }
  }
  const memoizedBuscarChavesAprovacao = useCallback(buscarChavesAprovacao, []);

  useEffect(() => {
    memoizedBuscarChavesAprovacao();
  }, [memoizedBuscarChavesAprovacao]);

  const toggleChaveSelected = useCallback(
    (chave) => {
      if (
        chave.nivel === 0 &&
        chave.codigo_usuario_digitacao !== chave.codigo_usuario_caixa
      )
        return;
      chave.selected = !chave.selected;
      if (chave.selected) {
        const existente = chaves.find((c) => c.codigo === chave.codigo);
        setChavesSelecionadas((prev) => [...prev, existente]);
      } else {
        setChavesSelecionadas((prev) =>
          prev.filter((c) => c.codigo !== chave.codigo)
        );
      }
    },
    [chaves]
  );

  const filtrarChaves = (chave) => {
    const { conta, usuarioDigitador, fornecedor } = appliedFilters;

    return (
      (conta === "" || chave.codigo_conta_portador === conta) &&
      (usuarioDigitador === "" ||
        chave.usuario_digitacao.nome === usuarioDigitador) &&
      (fornecedor === "" || chave.codigo_conta_fornecedor === fornecedor)
    );
  };

  const chavesFiltradas = chaves.filter(filtrarChaves);

  const contasMap = new Map();
  chaves.forEach((chave) => {
    if (!contasMap.has(chave.codigo_conta_portador)) {
      contasMap.set(chave.codigo_conta_portador, {
        value: chave.codigo_conta_portador,
        label: `${chave.codigo_conta_portador}: ${chave.conta_portador.apelido_conta}`,
      });
    }
  });
  const contas = Array.from(contasMap.values());

  const fornecedoresMap = new Map();
  chaves.forEach((chave) => {
    if (!fornecedoresMap.has(chave.codigo_conta_fornecedor)) {
      fornecedoresMap.set(chave.codigo_conta_fornecedor, {
        value: chave.codigo_conta_fornecedor,
        label: `${chave.codigo_conta_fornecedor}: ${chave.fornecedor.apelido_conta}`,
      });
    }
  });
  const fornecedores = Array.from(fornecedoresMap.values());

  const usuarios = [
    ...new Set(chaves.map((chave) => chave.usuario_digitacao.nome)),
  ].map((usuario) => ({
    value: usuario,
    label: usuario,
  }));

  function beginAprovarReprovar(shouldAprovar = true) {
    setShowModalAprovarReprovar(true);
    setShouldAprovar(shouldAprovar);
  }

  function onAprovadoReprovado() {
    toast.success(
      `Chaves ${shouldAprovar ? "aprovadas" : "reprovadas"} com sucesso`
    );
    setChavesSelecionadas([]);
    memoizedBuscarChavesAprovacao();
  }

  function toggleInfoModal(chave, infoType, parameter = null) {
    const isShowing = !showInfo;
    setShowInfo(isShowing);
    if (isShowing) {
      const view = modalViews[infoType];
      view.chave = chave;
      view.content = parameter || chave;
      setModalView(view);
    } else setModalView(blankView);
  }

  function reorder(e) {
    const ordering = orderingOptions.filter(
      (o) => o.value === e.target.value
    )[0];
    setOrdering(ordering);
    setChaves(chaves.sort(ordering.func));
  }

  function selecionarTodas() {
    const novasChavesSelecionadas = chavesFiltradas.map((chave) => {
      chave.selected = true;
      return chave;
    });
    setChavesSelecionadas(novasChavesSelecionadas.filter((chave) => chave.nivel !== 0));
  }

  function aplicarFiltros() {
    setAppliedFilters(filtros);
    setShowModalFiltros(false);
  }

  function limparFiltros() {
    setFiltros({
      conta: "",
      usuarioDigitador: "",
      fornecedor: "",
    });
    setAppliedFilters({
      conta: "",
      usuarioDigitador: "",
      fornecedor: "",
    });
    setShowModalFiltros(false);
  }

  function limparChavesSelecionadas() {
    const novasChaves = chaves.map((chave) => {
      chave.selected = false;
      return chave;
    });
    setChaves(novasChaves);
    setChavesSelecionadas([]);
  }

  function mainContent() {
    return (
      <div className="container flex-fill">
        <div className="row">
          <div className="col-12 col-md-4 order-md-2 mb-4 ">
            <ResumoChaves
              chavesSelecionadas={chavesSelecionadas}
              onClickSelecionarTodos={selecionarTodas}
              onAprovar={() => beginAprovarReprovar(true)}
              onReprovar={() => beginAprovarReprovar(false)}
              onLimparSelecao={limparChavesSelecionadas}
            />
          </div>
          <div className="col-12 col-md-8">
            <div className="mt-2 d-sm-flex justify-content-between align-items-center">
              <span className="mr-auto w-50">
                <strong>{chavesFiltradas.length}</strong> chaves na caixa
              </span>
              <OrderBy
                options={orderingOptions}
                value={ordering.value}
                onChange={reorder}
              />
              <Button
                className="ml-2 mr-1 mt-1 btn-sm d-flex align-items-center"
                variant="primary"
                onClick={() => setShowModalFiltros(true)}
              >
                <FaFilter className="mr-1" />
                Filtros
              </Button>
              <Button
                className="mt-1 btn-sm d-flex align-items-center"
                variant="secondary"
                onClick={limparFiltros}
              >
                <FaTrashAlt className="mr-1" />
                Limpar
              </Button>
            </div>
            <div className="row mt-3">
                <div className="col-12">
                  {appliedFilters.conta && (
                    <span className="badge badge-primary mr-2">
                      Conta: {appliedFilters.conta}
                    </span>
                  )}
                  {appliedFilters.usuarioDigitador && (
                    <span className="badge badge-primary mr-2">
                      Usuário Digitador: {appliedFilters.usuarioDigitador}
                    </span>
                  )}
                  {appliedFilters.fornecedor && (
                    <span className="badge badge-primary mr-2">
                      Fornecedor: {appliedFilters.fornecedor}
                    </span>
                  )}
                </div>
              </div>
            {chavesFiltradas.map((chave) => (
              <CardChave
                key={chave.codigo}
                chave={chave}
                onCardClick={() => toggleChaveSelected(chave)}
                onShowInfoRequested={toggleInfoModal}
              />
            ))}
          </div>
        </div>
        <ScrollToTopButton
          scrollStepInPx="50"
          delayInMs="16.66"
          className="d-block d-sm-none"
        />
      </div>
    );
  }

  return (
    <>
      <Header title="Caixa de Aprovação">
        <Button onClick={memoizedBuscarChavesAprovacao} disabled={isLoading}>
          ATUALIZAR
        </Button>
      </Header>
      {isLoading && <Loading />}
      {!isLoading && mainContent()}
      <ModalAprovarReprovar
        show={showModalAprovarReprovar}
        onCancel={() => setShowModalAprovarReprovar(false)}
        aprovando={shouldAprovar}
        onSuccess={onAprovadoReprovado}
        chaves={chavesSelecionadas}
      />
      <GenericModal
        show={showInfo}
        title={modalView.title}
        titleChildren={<ChaveId chave={modalView.chave} />}
        onCancel={toggleInfoModal}
        content={modalView.render(modalView.content)}
      />
      <Modal show={showModalFiltros} onHide={() => setShowModalFiltros(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Filtros</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FiltrosChaves
            filtros={filtros}
            setFiltros={setFiltros}
            contas={contas}
            usuarios={usuarios}
            fornecedores={fornecedores}
            onApplyFilters={aplicarFiltros}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={aplicarFiltros}>
            Aplicar Filtros
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
