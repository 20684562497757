import styled from "styled-components";

export const ScrollToTopButtonContainer = styled.button`
  opacity: 0.3;
  background-color: #333;
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  border-radius: 5px;
  border: none;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }

  .arrow-up {
    color: white;
  }
`;
