import React, { useState } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { FaSignOutAlt, FaUser, FaCalendarAlt } from "react-icons/fa";
import { useHistory } from "react-router-dom";

import getLinks from "./links";

import AuthStorage from "~/Utils/AuthStorage";
import { MainNavbar } from "./styles";
import Logo from "../Logo";

export default function NavbarHeader() {
  const [expanded, setExpanded] = useState(false);

  const user = AuthStorage.getUser();
  const history = useHistory();

  function logout() {
    AuthStorage.clear();
    history.push("/login?logout=true");
  }
  const navigate = (event, to) => {
    // event.stopPropagation();
    // event.preventDefault();
    setExpanded(false);
    history.push(to); // <Link to> não está funcionando e está causando um page refresh
  };

  return (
    <MainNavbar
      fixed="top"
      expand="lg"
      variant="dark"
      expanded={expanded}
      onToggle={() => setExpanded((prev) => !prev)}
    >
      <Navbar.Brand
        onClick={(e) => navigate(e, "/")}
        className="cursor-pointer"
      >
        <Logo />
      </Navbar.Brand>

      <Navbar.Toggle className="border-0" />

      <Navbar.Collapse>
        <Nav className="mr-auto">
          {getLinks().map((item, index) =>
            item.dropdown ? (
              <NavDropdown
                title={
                  <>
                    {item.icon}
                    <span>{item.label}</span>
                  </>
                }
                id={index}
                key={index}
              >
                {item.dropdown.map((ditem, dindex) =>
                  ditem.divider ? (
                    <NavDropdown.Divider key={dindex} />
                  ) : ditem.header ? (
                    <NavDropdown.Header key={dindex}>
                      {ditem.header}
                    </NavDropdown.Header>
                  ) : (
                    <NavDropdown.Item
                      key={dindex}
                      onClick={(e) => navigate(e, ditem.href)}
                    >
                      {ditem.icon}
                      <span>{ditem.label}</span>
                    </NavDropdown.Item>
                  )
                )}
              </NavDropdown>
            ) : (
              <Navbar.Text key={index} onClick={(e) => navigate(e, item.href)}>
                {item.icon}
                <span>{item.label}</span>
              </Navbar.Text>
            )
          )}
        </Nav>

        <Nav className="ml-auto">
          <NavDropdown
            alignRight
            title={
              <>
                <FaUser />
                {user.nome}
              </>
            }
          >
            <NavDropdown.Item
              onClick={(e) => navigate(e, `/atendimento/agendamento`)}
            >
              <FaCalendarAlt />
              Agenda
            </NavDropdown.Item>
            <NavDropdown.Item onClick={logout}>
              <FaSignOutAlt />
              Sair
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </MainNavbar>
  );
}
