import React from "react";
import { useUsuariosAtendentesTableInstance } from "./hooks";
import DefaultTableRenderer from "~/Components/ReactTable/DefaultTableRenderer";
import { ResponsiveTableContainer } from "~/Components/ReactTable/styles";

const TabelaAtendentes = ({ usuarios, editar, excluir }) => {
  const tableInstance = useUsuariosAtendentesTableInstance(
    usuarios,
    editar,
    excluir
  );

  return (
    <ResponsiveTableContainer className="m-4">
      <DefaultTableRenderer
        tableInstance={tableInstance}
        title="Atendentes"
        size="sm"
      />
    </ResponsiveTableContainer>
  );
};

export default TabelaAtendentes;
