import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Form, Button, Alert } from "react-bootstrap";
import { Formik } from "formik";

import { ColoredModal } from "./styles";

import { justificativaValidationSchema } from "~/Validations";

import api from "~/Services/api";
import colors from "~/Utils/Styling/colors";
ModalAprovarReprovar.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  aprovando: PropTypes.bool.isRequired,
};

export default function ModalAprovarReprovar({
  show,
  onCancel,
  aprovando,
  chaves,
  onSuccess,
}) {
  const [submitError, setSubmitError] = useState(null);

  useEffect(() => {
    setSubmitError(null);
  }, [show]);

  async function doAprovarOuReprovar(values, actions) {
    const request = {
      chaves,
      ...values,
    };
    try {
      aprovando
        ? await api.aprovarChaves(request)
        : await api.reprovarChaves(request);
      actions.setSubmitting(false);
      actions.resetForm();
      onSuccess();
      onCancel();
    } catch (error) {
      setSubmitError(error.message || error);
      actions.setSubmitting(false);
    }
  }

  return (
    <Formik
      validationSchema={justificativaValidationSchema}
      onSubmit={doAprovarOuReprovar}
      initialValues={{
        justificativa: "",
      }}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
        isSubmitting,
        resetForm,
      }) => (
        <ColoredModal
          color={aprovando ? colors.success : colors.danger}
          show={show}
          onHide={onCancel}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {aprovando ? "APROVAR CHAVES" : "REPROVAR CHAVES"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert variant={aprovando ? "success" : "danger"}>
              Escreva uma justificativa para{" "}
              <strong>{aprovando ? "aprovar" : "reprovar"}</strong> as chaves.
            </Alert>
            <Form.Group>
              <Form.Label>Justificativa</Form.Label>
              <Form.Control
                as="textarea"
                type="text"
                placeholder="Justificativa"
                name="justificativa"
                value={values.justificativa}
                onChange={handleChange}
                disabled={isSubmitting}
                isInvalid={touched.justificativa && errors.justificativa}
              />
              <Form.Control.Feedback type="invalid">
                {errors.justificativa}
              </Form.Control.Feedback>
            </Form.Group>
            {submitError && <Alert variant="danger">{submitError}</Alert>}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant={aprovando ? "success" : "danger"}
              type="submit"
              className="mb-1"
              onClick={handleSubmit}
              disabled={!values.justificativa}
            >
              {aprovando ? "APROVAR" : "REPROVAR"}
            </Button>
            <Button
              variant="outline-primary"
              disabled={isSubmitting}
              onClick={() => {
                resetForm();
                onCancel();
              }}
            >
              Cancelar
            </Button>
          </Modal.Footer>
        </ColoredModal>
      )}
    </Formik>
  );
}
