import React from "react";
import { Form, Button, InputGroup } from "react-bootstrap";

import Hint from "~/Components/Hint";

const ConfiguracaoGeralAtendimentos = ({
  onSave,
  maximoAtendimentosPorDia,
  setMaximoAtendimentosPorDia,
}) => {
  return (
    <Form inline>
      <Form.Label>
        Máximo de atendimentos
        <Hint
          title="Máximo de atendimentos"
          content="Número máximo de atendimentos por atendente ao dia"
        />
      </Form.Label>
      <InputGroup>
        <Form.Control
          className="ml-1"
          // style={{ width: 100 }}
          type="number"
          min="1"
          value={maximoAtendimentosPorDia || ""}
          onChange={(e) => setMaximoAtendimentosPorDia(e.target.value)}
        ></Form.Control>
        <InputGroup.Append>
          <Button
            variant="outline-primary"
            onClick={() => onSave(maximoAtendimentosPorDia)}
          >
            Salvar
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </Form>
  );
};

export default ConfiguracaoGeralAtendimentos;
