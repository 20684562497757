import * as yup from "yup";

export const loginValidationSchema = yup.object().shape({
  username: yup.string().required("Nome de usuário é obrigatório"),
  password: yup.string().max(10).required("Senha é obrigatória"),
});

export const justificativaValidationSchema = yup.object().shape({
  justificativa: yup.string().required("Justificativa é obrigatória"),
});

export const configAtendenteValidationSchema = yup.object().shape({
  codigo: yup.number().integer().min(1, "Usuário obrigatório"),
  horarios: yup.array().required("Informe ao menos um horário"),
});

export const agendamentoValidationSchema = yup.object().shape({
  nome: yup.string().required("Informe o nome"),
  codigo_tipo_atendimento: yup
    .number()
    .integer()
    .required("Informe um tipo de serviço"),
});
