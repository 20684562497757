import React from "react";
import PropTypes from "prop-types";
import { InputGroup, Form } from "react-bootstrap";
import { FaSortAmountDown } from "react-icons/fa";

OrderBy.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
};

export default function OrderBy({ onChange, value, options }) {
  return (
    <InputGroup size="sm">
      <InputGroup.Prepend>
        <InputGroup.Text>
          <FaSortAmountDown />
        </InputGroup.Text>
      </InputGroup.Prepend>
      <Form.Control as="select" custom onChange={onChange} value={value}>
        {options.map((o, index) => (
          <option key={index} value={o.value}>
            {o.label}
          </option>
        ))}
      </Form.Control>
    </InputGroup>
  );
}
