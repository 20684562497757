import React from "react";
import { FaQuestionCircle } from "react-icons/fa";
import { Popover, OverlayTrigger } from "react-bootstrap";

const Hint = ({ title, content, trigger, size = 16, placement = "right" }) => {
  return (
    <OverlayTrigger
      trigger={trigger || ["focus", "hover"]}
      placement={placement}
      rootClose={trigger === "click"}
      overlay={
        <Popover id="popover-basic">
          <Popover.Title as="h3">{title}</Popover.Title>
          <Popover.Content>{content}</Popover.Content>
        </Popover>
      }
    >
      <FaQuestionCircle color="#007BFF" size={size} className="ml-2" />
    </OverlayTrigger>
  );
};

export default Hint;
