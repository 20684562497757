import httpService from "./httpService";
import qs from "querystring";

class api {
  onUnauthorized(action) {
    httpService.onUnauthorized(action);
  }
  login(credentials, env = process.env.REACT_APP_DB) {
    return httpService.post(
      "usuario/login",
      qs.stringify({ ...credentials, env, grant_type: "password" }), //data
      { "Content-Type": "application/x-www-form-urlencoded" } //headers
    );
  }
  getClaims = () => httpService.get("usuario/claims");

  //caixa de aprovação
  buscarChavesAprovacao = () => httpService.get("chaves");
  aprovarChaves = (data) => httpService.post("chaves/aprovar", data);
  reprovarChaves = (data) => httpService.post("chaves/reprovar", data);
  verificarTemChaveNaCaixa = () => httpService.get("chaves/verificar");

  //usuários
  buscarUsuariosPorTermo = (termo) =>
    httpService.get("usuario/list?termo=" + termo);

  //atendimento
  criarOuEditarConfigAtendente = (config) =>
    httpService.post("agendamento/atendente/atualizar", config);
  buscarLocaisAtendimento = (codigoUsuario) => {
    let url = "agendamento/locais";
    if (codigoUsuario) url += "?codigoUsuario=" + codigoUsuario;
    return httpService.get(url);
  };
  buscarServicos = (agendavel) =>
    httpService.get(
      "agendamento/servicos" + (agendavel ? "?agendavel=true" : "")
    );
  atualizarServico = (model) =>
    httpService.post("agendamento/servico/atualizar", model);
  atualizarEscalasServico = (model) =>
    httpService.post("agendamento/servico/escalas/atualizar", model);
  buscarParametrosAgendamento = () =>
    httpService.get("agendamento/servicos/config");
  atualizarParametroMaximoAtendimentosPorDia = (config) =>
    httpService.post("agendamento/servicos/config", config);
  buscarUsuariosAtendentes = () => httpService.get("agendamento/atendentes");
  excluirConfiguracoesAtendimentoUsuario = (codigoUsuario) =>
    httpService.get(`agendamento/atendente/${codigoUsuario}/excluir`);
  buscarAgendamentos = (filtros) =>
    httpService.post("agendamento/lista", filtros);
  buscarDisponibilidadeServico = (filtros) =>
    httpService.post("agendamento/disponibilidade", filtros);
  buscarCalendarios = () => httpService.get(`agendamento/calendarios`);
  criarAgendamento = (model) => httpService.post("agendamento/criar", model);
  buscarAtendentesComHorarios = (termo, diaDaSemana) =>
    httpService.get(
      `agendamento/atendentes/buscar?termo=${termo}&diaDaSemana=${diaDaSemana}`
    );
  buscarRelatorioAgendamento = (filtros) =>
    httpService.post("agendamento/relatorio", filtros);
}

export default new api();
