import styled from "styled-components";

const EnvironmentTagContainer = styled.div`
  height: 15px;
  font-size: 10px;
  top: 0;
  border-radius: 0 0 7px 7px;
  text-transform: uppercase;
  font-weight: 500;
  position: fixed;
  width: 100%;
  z-index: 5000;
  text-align: center;
  left: calc(50% - 100px);
  max-width: 200px;

  @media (min-width: 768px) {
    left: calc(50% - 200px);
    max-width: 400px;
  }

  background: ${props => props.color};
`;

export default EnvironmentTagContainer;
