import React, { useState, useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import { FaUserClock } from "react-icons/fa";
import { toast } from "react-toastify";

import log from "~/Utils/Log";
import api from "~/Services/api";

import Header from "~/Components/Header";
import Loading from "~/Components/Loading";
import TabelaAtendentes from "~/Components/Atendimento/ConfiguracaoAtendentes/TabelaAtendentes";
import ModalEdicaoAtendente from "~/Components/Atendimento/ConfiguracaoAtendentes/ModalEdicaoAtendente";

const ConfiguracaoAtendentes = () => {
  const [usuarios, setUsuarios] = useState([]);

  const [isBusy, setIsBusy] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [atendenteEmEdicao, setAtendenteEmEdicao] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const usuarios = await api.buscarUsuariosAtendentes();
        setUsuarios(usuarios);
      } catch (err) {
        console.error(err);
        toast.error("Falha ao buscar os atendentes");
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const editar = (codigoUsuario) => {
    log.debug("editando " + codigoUsuario);
    if (!codigoUsuario) setAtendenteEmEdicao({});
    else {
      const existente = usuarios.find((u) => u.codigo === codigoUsuario);
      setAtendenteEmEdicao(existente);
    }
  };

  const excluir = async (codigoUsuario) => {
    log.debug("excluindo " + codigoUsuario);
    setIsBusy(true);

    try {
      await api.excluirConfiguracoesAtendimentoUsuario(codigoUsuario);
      setUsuarios(usuarios.filter((u) => u.codigo !== codigoUsuario));
      toast.success("Configuração excluída com sucesso");
    } catch (err) {
      console.error(err);
      toast.error("Falhaao excluir as configurações do atendente");
    } finally {
      setIsBusy(false);
    }
  };

  const mainContent = () => {
    if (usuarios.length === 0)
      return (
        <Container className="text-muted text-center mt-4">
          <FaUserClock fontSize={72} />
          <h5>Nenhum atendente configurado ainda</h5>
          <Button variant="link" onClick={() => editar(null)}>
            Clique aqui para criar uma configuração para um atendente
          </Button>
        </Container>
      );
    return (
      <Container className="px-0">
        <TabelaAtendentes
          usuarios={usuarios}
          editar={editar}
          excluir={excluir}
        />
      </Container>
    );
  };
  return (
    <>
      <Header title="Configuração de Atendentes">
        <Button onClick={() => editar(null)} disabled={isLoading || isBusy}>
          Novo
        </Button>
      </Header>

      {isLoading && <Loading />}
      {!isLoading && mainContent()}

      <ModalEdicaoAtendente
        show={!!atendenteEmEdicao}
        onCancel={() => setAtendenteEmEdicao(null)}
        atendente={atendenteEmEdicao}
        onSuccess={(atendente) => {
          log.debug(atendente);
          const existente = usuarios.find((u) => u.codigo === atendente.codigo);
          if (existente) {
            setUsuarios((prev) =>
              prev.map((u) => (u.codigo === atendente.codigo ? atendente : u))
            );
          } else {
            setUsuarios((prev) => [...prev, atendente]);
          }
          toast.success("Atendente configurado com sucesso");
        }}
      />
    </>
  );
};

export default ConfiguracaoAtendentes;
