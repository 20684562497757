import React, { useMemo } from "react";
import { useTable, useSortBy, usePagination, useFilters } from "react-table";
import { Button, ButtonGroup } from "react-bootstrap";
import DefaultColumnFilter from "~/Components/ReactTable/DefaultColumnFilter";
import ConfirmButton from "~/Components/ConfirmButton";

export const useUsuariosAtendentesTableInstance = (
  usuarios,
  onEditar,
  onExcluir
) => {
  const columns = useMemo(
    () => [
      {
        Header: "Nome",
        accessor: "nome",
      },
      {
        Header: "Departamento",
        accessor: "departamento",
      },
      {
        Header: () => null, // No header
        id: "action", // It needs an ID
        Cell: ({ row }) => (
          <ButtonGroup className="d-flex">
            <Button
              variant="outline-primary"
              size="sm"
              // className="p-1"
              onClick={() => onEditar(row.original.codigo)}
            >
              Editar
            </Button>
            <ConfirmButton
              confirmTitle="Excluir configurações?"
              confirmText={
                <>
                  Deseja excluir as configurações do(a) atendentente{" "}
                  <strong>{row.original.nome}</strong>?"
                </>
              }
              onConfirm={() => onExcluir(row.original.codigo)}
              variant="outline-danger"
              size="sm"
              // className="p-1"
            >
              Excluir
            </ConfirmButton>
          </ButtonGroup>
        ),
      },
    ],
    [onEditar, onExcluir]
  );

  const data = useMemo(
    () =>
      usuarios.map((usuario) => {
        return {
          nome: usuario.nome,
          departamento: usuario.departamento.descricao,
          codigo: usuario.codigo,
        };
      }),
    [usuarios]
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn: useMemo(
        () => ({
          Filter: DefaultColumnFilter,
        }),
        []
      ),
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        sortBy: useMemo(() => [{ id: "nome", desc: false }], []),
      },
      disableSortRemove: true,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return tableInstance;
};
