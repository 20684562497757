import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { RiListSettingsLine } from "react-icons/ri";
import { toast } from "react-toastify";

import log from "~/Utils/Log";
import api from "~/Services/api";

import Header from "~/Components/Header";
import Loading from "~/Components/Loading";
import StringFilter from "~/Components/StringFilter";
import DisplayServico from "~/Components/Atendimento/ConfiguracaoServicos/DisplayServico";
import ModalEscalasServico from "~/Components/Atendimento/ConfiguracaoServicos/ModalEscalasServico";
import ConfiguracaoGeralAtendimentos from "~/Components/Atendimento/ConfiguracaoServicos/ConfiguracaoGeralAtendimentos";

const ConfiguracaoServicos = () => {
  const [servicos, setServicos] = useState([]);
  const [filtro, setFiltro] = useState("");
  const [servicosFiltrados, setServicosFiltrados] = useState([]);
  const [servicoEmEdicao, setServicoEmEdicao] = useState(null);
  const [maximoAtendimentosPorDia, setMaximoAtendimentosPorDia] = useState("");
  const [atendentes, setAtendentes] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        let servicos = await api.buscarServicos();
        servicos = servicos.map((s) => {
          s.temSubTipos = s.sub_tipos && s.sub_tipos.length > 0;
          return s;
        });
        setServicos(servicos);
        setServicosFiltrados(servicos);
        const maxDias = await api.buscarParametrosAgendamento();
        setMaximoAtendimentosPorDia(maxDias);
      } catch (err) {
        console.error(err);
        toast.error("Falha ao buscar os serviços");
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const atendentes = await api.buscarUsuariosAtendentes();
        setAtendentes(atendentes);
      } catch (err) {
        console.error(err);
        toast.error("Falha ao buscar os atendentes");
      } finally {
      }
    })();
  }, []);

  const atualizarServico = async (
    codigo,
    codigoSubTipo,
    agendavel,
    duracaoMinutos
  ) => {
    log.debug(
      `Editando serviço ${codigo} - ${codigoSubTipo} - ${agendavel} - ${duracaoMinutos}`
    );

    const model = {
      codigo_atendimento: codigo,
      codigo_sub_tipo_atendimento: codigoSubTipo,
      agendavel,
      duracao_minutos: duracaoMinutos,
    };

    setServicoOuSubServico(model);
    doAtualizarServico(model);
  };

  const setServicoOuSubServico = (model) => {
    const {
      codigo_atendimento,
      codigo_sub_tipo_atendimento,
      ...config
    } = model;
    log.debug(
      `setServicoOuSubServico - ${codigo_atendimento} - ${codigo_sub_tipo_atendimento} - ${JSON.stringify(
        config
      )}`
    );
    setServicos((prev) => {
      return setServicoEditado(
        prev,
        codigo_atendimento,
        codigo_sub_tipo_atendimento,
        config
      );
    });
    setServicosFiltrados((prev) => {
      return setServicoEditado(
        prev,
        codigo_atendimento,
        codigo_sub_tipo_atendimento,
        config
      );
    });
  };

  const setServicoEditado = (
    prev,
    codigo_atendimento,
    codigo_sub_tipo_atendimento,
    config
  ) => {
    return prev.map((s) => {
      if (s.codigo !== codigo_atendimento) return s;
      if (codigo_sub_tipo_atendimento) {
        s.sub_tipos = s.sub_tipos.map((sub) => {
          if (sub.codigo === codigo_sub_tipo_atendimento)
            return {
              ...sub,
              ...config,
            };
          return sub;
        });
        return s;
      } else {
        return {
          ...s,
          ...config,
        };
      }
    });
  };

  const doAtualizarServico = async (model) => {
    try {
      await api.atualizarServico(model);
    } catch (err) {
      console.error(err);
      toast.error("Falha ao atualizar o serviço");
    } finally {
    }
  };

  const atualizarParametroMaximoAtendimentosPorDia = async (maximo) => {
    try {
      await api.atualizarParametroMaximoAtendimentosPorDia({
        maximo_atendimentos_por_dia: maximo,
      });
      toast.success("Parâmetro atualizado");
    } catch (err) {
      console.error(err);
      toast.error("Falha ao atualizar o parâmetro");
    }
  };

  const handleFilterChange = (e) => {
    const filtro = e.target.value;
    setFiltro(filtro ? filtro.toLowerCase() : null);
    aplicarFiltro(filtro);
  };

  const aplicarFiltro = (filtro) => {
    log.debug("Aplicando filtro: " + filtro);
    if (!filtro) {
      setServicosFiltrados(servicos);
      return;
    }
    const copy = JSON.parse(JSON.stringify(servicos));
    const svcs = copy
      .filter((s) => {
        if (s.sub_tipos && s.sub_tipos.length > 0) {
          const subs = s.sub_tipos.filter((sub) =>
            sub.descricao.toLowerCase().includes(filtro)
          );
          s.sub_tipos = subs;
          return true;
        }

        return s.descricao.toLowerCase().includes(filtro);
      })
      .filter((s) => {
        if (!s.sub_tipos || s.sub_tipos.length === 0)
          return s.descricao.toLowerCase().includes(filtro);
        return true;
      });

    setServicosFiltrados(svcs);
  };

  const mainContent = () => {
    if (servicos.length === 0)
      return (
        <Container className="text-muted text-center my-4">
          <RiListSettingsLine fontSize={72} />
          <h5>Nenhum serviço disponível para configuração</h5>
        </Container>
      );
    return (
      <Container className="mt-4">
        <Row>
          <Col lg={6}>
            <ConfiguracaoGeralAtendimentos
              maximoAtendimentosPorDia={maximoAtendimentosPorDia}
              onSave={atualizarParametroMaximoAtendimentosPorDia}
              setMaximoAtendimentosPorDia={setMaximoAtendimentosPorDia}
            />
          </Col>
          <Col lg={6}>
            <StringFilter
              filter={filtro}
              onFilterChanged={handleFilterChange}
              onFilterCleared={() => {
                setFiltro("");
                aplicarFiltro(null);
              }}
            />
          </Col>
        </Row>
        <hr />
        {servicosFiltrados.map((s) => {
          const configuravel = s.sub_tipos.length === 0;
          return (
            <div
              key={s.codigo}
              className={`py-1 ${configuravel ? "bg-hover-light" : ""}`}
            >
              <DisplayServico
                servico={s}
                atualizarServico={atualizarServico}
                onEscalaClicked={() => setServicoEmEdicao(s)}
              />
              {s.sub_tipos.map((sub) => (
                <div key={sub.codigo} className="py-1 bg-hover-light">
                  <DisplayServico
                    servico={sub}
                    atualizarServico={atualizarServico}
                    onEscalaClicked={() => setServicoEmEdicao(sub)}
                  />
                </div>
              ))}
            </div>
          );
        })}
      </Container>
    );
  };
  return (
    <>
      <Header title="Configuração de Serviços"></Header>

      {isLoading && <Loading />}
      {!isLoading && mainContent()}

      <ModalEscalasServico
        servico={servicoEmEdicao}
        atendentes={atendentes}
        onCancel={() => setServicoEmEdicao(null)}
        onSuccess={(servico, escalas) => {
          toast.success("Escalas do serviço definidas com sucesso");
          setServicoEmEdicao(null);
          log.debug("success: " + JSON.stringify(servico));
          const model = {
            codigo_atendimento:
              servico.codigo_tipo_atendimento || servico.codigo,
            codigo_sub_tipo_atendimento: servico.codigo_tipo_atendimento
              ? servico.codigo
              : null,
            escalas: escalas,
          };
          setServicoOuSubServico(model);
        }}
      />
    </>
  );
};

export default ConfiguracaoServicos;
