import React from "react";
import styled from "styled-components";

const LogoContainer = styled.em.attrs(props => ({
  size: props.size || 25
}))`
  font-family: "Righteous";
  font-size: ${props => props.size}px;
  display: block;
  text-align: center;

  span {
    color: #aaa;
  }
`;
const Logo = ({ size }) => (
  <LogoContainer size={size}>
    GES<span>LITE</span>
  </LogoContainer>
);

export default Logo;
