import styled from "styled-components";

export const ColorPickerWrapper = styled.div`
.cp-popover {
  position: absolute;
  zIndex: 2,
},
`;

export const ColorSwatch = styled.div`
  padding: 5px;
  background: ${({ disabled }) => (disabled ? "#D4D4D4" : "#fff")};
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
`;

export const ColorDisplay = styled.div`
  width: 36px;
  height: 14px;
  border-radius: 2px;
  background-color: ${({ hex }) => hex};
`;
