import React from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaCalendarAlt } from "react-icons/fa";
import workflow from "~/Images/workflow.svg";
import AuthStorage from "~/Utils/AuthStorage";
import { caracteristicas } from "~/constants";

const Inicio = () => {
  const sideIconStyle = {
    height: 50,
    width: 50,
    marginRight: 15,
  };
  return (
    <Container className="mt-4">
      <Row>
        <Col xs={6} md={6} className="mb-4">
          <Card className="p-4 h-100">
            <Link
              to="/negocios/caixa-aprovacao"
              className="text-decoration-none text-dark"
            >
              <div className="d-flex flex-column flex-md-row align-items-center align-items-md-start">
                <img
                  src={workflow}
                  alt="Caixa de Aprovação"
                  style={sideIconStyle}
                  className="mb-3 mb-md-0"
                />
                <div>
                  <h4>Caixa de aprovação</h4>
                  <p>
                    Visualize, aprove e reprove chaves da sua caixa no workflow
                    de pagamentos.
                  </p>
                </div>
              </div>
            </Link>
          </Card>
        </Col>
        {AuthStorage.temCaracteristica(caracteristicas.RealizaAgendamento) && (
          <Col xs={12} md={6} className="mb-4">
            <Card className="p-4 h-100">
              <Link
                to="/atendimento/agendamento"
                className="text-decoration-none text-dark"
              >
                <div className="d-flex flex-column flex-md-row align-items-center align-items-md-start">
                  <FaCalendarAlt
                    style={sideIconStyle}
                    className="mb-3 mb-md-0"
                  />
                  <div>
                    <h4>Agendamento</h4>
                    <p>
                      Agende clientes para atendimento presencial na Central de
                      Atendimento de Vendas.
                    </p>
                  </div>
                </div>
              </Link>
            </Card>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default Inicio;
