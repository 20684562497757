import React from "react";
import { GiReceiveMoney } from "react-icons/gi";
import { IoIosDocument } from "react-icons/io";

import format from "~/Utils/format";
import Tag from "~/Components/Tag";

const RetencaoTemplate = ({ retencao }) => (
  <div>
    <small className="text-muted">
      <span className="mr-1">
        {retencao.codigo_grupo_empresa}/{retencao.codigo_conta_portador}:
      </span>
      {retencao.fornecedor.apelido_conta}
    </small>
    <small className="float-lg-right d-block d-lg-inline">
      {format.date(retencao.data_vencimento)}
    </small>
    <h5>{format.money(retencao.valor)}</h5>
    <div className="d-block">
      <Tag
        icon={<IoIosDocument />}
        text={retencao.descricao_tipo_guia}
        title="Tipo guia"
      />
      <Tag
        icon={<GiReceiveMoney />}
        text={retencao.descricao_modalidade}
        title="Modalidade"
      />
    </div>
    <hr />
  </div>
);

export default RetencaoTemplate;
