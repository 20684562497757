import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { Modal, Form, Button, Alert, Row, Col } from "react-bootstrap";
import Select from "react-select";
import BeatLoader from "react-spinners/BeatLoader";

import api from "~/Services/api";
import log from "~/Utils/Log";
import {
  diasDaSemanaOptions,
  semanaDeTrabalhoOptions,
  diasDaSemanaById,
} from "~/constants";

import { configAtendenteValidationSchema } from "~/Validations";
import GenericModal from "~/Components/GenericModal";
import SelectUsuario from "~/Components/SelectUsuario";
import Tag from "~/Components/Tag";
import ColorPicker from "~/Components/ColorPicker";

const ModalEdicaoAtendente = ({ show, onCancel, atendente, onSuccess }) => {
  const [submitError, setSubmitError] = useState(null);
  const [errorsAddHorario, setErrorsAddHorario] = useState({});

  const [locaisAtendimento, setLocaisAtendimento] = useState([]);
  const [buscandoLocaisAtendimento, setBuscandoLocaisAtendimento] = useState(
    false
  );
  const [erroBuscaLocaisAtendimento, setErroBuscaLocaisAtendimento] = useState(
    null
  );

  useEffect(() => {
    setSubmitError(null);
    setErrorsAddHorario({});
    log.debug(atendente);
    if (atendente && !!atendente.codigo)
      buscarLocaisAtendimento(atendente.codigo);
  }, [show, atendente]);

  const criarOuEditarConfiguracoesAtendente = async (values, actions) => {
    try {
      log.debug("Enviando horários: " + JSON.stringify(values));
      console.log(values);
      const request = {
        codigo_usuario: values.codigo,
        cor_calendario: values.cor_calendario,
        horarios: values.horarios.map((h) => ({
          hora_inicial: h.hora_inicial,
          hora_final: h.hora_final,
          dias_semana: h.dias_semana.reduce((total, d) => total + d, 0),
        })),
      };
      log.debug("Request: " + JSON.stringify(request));
      actions.setSubmitting(true);
      const atendente = await api.criarOuEditarConfigAtendente(request);
      actions.setSubmitting(false);
      actions.resetForm();
      onSuccess(atendente);
      onCancel();
    } catch (error) {
      console.error(error);
      setSubmitError(error.message);
      actions.setSubmitting(false);
    }
  };

  const buscarLocaisAtendimento = async (codigoUsuario) => {
    setBuscandoLocaisAtendimento(true);
    setErroBuscaLocaisAtendimento(null);
    try {
      const locaisAtendimento = await api.buscarLocaisAtendimento(
        codigoUsuario
      );
      setLocaisAtendimento(locaisAtendimento);
    } catch (error) {
      console.error(error);
      setErroBuscaLocaisAtendimento("Falha ao buscar os locais de atendimento");
    } finally {
      setBuscandoLocaisAtendimento(false);
    }
  };

  const valoresIniciaisHorario = {
    dias_semana: semanaDeTrabalhoOptions,
    hora_inicial: "",
    hora_final: "",
  };

  const coresIniciais = [
    "#b80000",
    "#db3e00",
    "#fccb00",
    "#008b02",
    "#006b76",
    "#1273de",
    "#004dcf",
    "#5300eb",
  ];

  const renderModalBody = () => {
    return (
      <Formik
        validationSchema={configAtendenteValidationSchema}
        onSubmit={criarOuEditarConfiguracoesAtendente}
        validateOnChange
        initialValues={{
          codigo: 0,
          horarios: [],
          servicos_atendidos: [],
          cor_calendario:
            coresIniciais[
              Math.floor(Math.random() * (coresIniciais.length - 1))
            ],

          //campos para o "form" de horário
          ...valoresIniciaisHorario,

          ...atendente,
        }}
      >
        {({
          handleSubmit,
          handleChange,
          setFieldValue,
          setValues,
          values,
          touched,
          errors,
          isSubmitting,
          resetForm,
        }) => {
          log.log("Erros " + JSON.stringify(errors));
          log.log("Erros Add Horario" + JSON.stringify(errorsAddHorario));
          log.debug("Values " + JSON.stringify(values));

          const adicionarHorario = () => {
            log.debug(
              "adicionando horário com values: " + JSON.stringify(values)
            );
            const errors = {};
            setErrorsAddHorario(errors);
            const horarios = values.horarios;
            let hasAnyError = false;

            if (!values.hora_inicial) {
              errors.hora_inicial = "Informe um horário de entrada";
              hasAnyError = true;
            }
            if (!values.hora_final) {
              errors.hora_final = "Informe um horário de saída";
              hasAnyError = true;
            }
            if (values.dias_semana.length === 0) {
              errors.dias_semana = "Informe ao menos 1 dia da semana";
              hasAnyError = true;
            }
            if (hasAnyError) {
              return;
            }
            horarios.push({
              dias_semana: values.dias_semana.map((d) => d.value),
              hora_final: values.hora_final,
              hora_inicial: values.hora_inicial,
            });

            setValues({
              ...values,
              horarios: horarios,
              ...valoresIniciaisHorario,
            });
          };

          const excluirHorario = (index) => {
            const horarios = values.horarios.filter((h, i) => i !== index);
            setFieldValue("horarios", horarios);
          };

          const renderLocaisAtendimento = () => {
            if (!values.codigo)
              return (
                <small>
                  Selecione um usuário para buscar seus locais de atendimento
                </small>
              );

            if (buscandoLocaisAtendimento)
              return <BeatLoader size={8} color={"#ccc"} />;
            if (erroBuscaLocaisAtendimento)
              return (
                <small className="text-danger">
                  {erroBuscaLocaisAtendimento}
                </small>
              );
            if (locaisAtendimento.length === 0)
              return (
                <small className="text-danger">
                  Usuário não possui locais de atendimento cadastrados!
                  Cadastre-os no GES.
                </small>
              );
            return locaisAtendimento.map((l) => (
              <Tag key={l.codigo} text={l.descricao} />
            ));
          };

          return (
            <>
              <Modal.Body>
                <Form.Group>
                  <Form.Label>Usuário</Form.Label>

                  <SelectUsuario
                    isDisabled={!!atendente.codigo}
                    value={{
                      value: values.codigo,
                      label: values.nome,
                    }}
                    onChange={(option) => {
                      log.debug("Recebida a option " + JSON.stringify(option));
                      setValues({
                        ...values,
                        nome: option.label,
                        codigo: option.value,
                      });
                      buscarLocaisAtendimento(option.value);
                    }}
                  />
                  {errors.codigo && (
                    <small className="form-text text-danger">
                      {errors.codigo}
                    </small>
                  )}
                </Form.Group>
                {/* <div className="d-flex">
                  <Button variant="link" size="sm" className="ml-auto">
                    Copiar horários de outro atendente
                  </Button>
                </div> */}
                <div className="mb-2">
                  <h5>Locais de Atendimento</h5>
                  {renderLocaisAtendimento()}
                </div>

                <Form.Group className="d-flex">
                  <Form.Label className="mr-1">Cor do Calendário</Form.Label>
                  <ColorPicker
                    hex={values.cor_calendario}
                    onChange={(hex) => {
                      setFieldValue("cor_calendario", hex);
                    }}
                    disabled={!values.codigo}
                  />
                </Form.Group>

                <div>
                  <h5>Horários</h5>

                  <Row className="justify-content-between">
                    <Col sm="6">
                      <Form.Group>
                        <Form.Label>Entrada</Form.Label>
                        <Form.Control
                          type="time"
                          placeholder="Início"
                          value={values.hora_inicial}
                          name="hora_inicial"
                          isInvalid={errorsAddHorario.hora_inicial}
                          disabled={!values.codigo}
                          onChange={handleChange}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errorsAddHorario.hora_inicial}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col sm="6">
                      <Form.Group>
                        <Form.Label>Saída</Form.Label>
                        <Form.Control
                          type="time"
                          placeholder="Fim"
                          value={values.hora_final}
                          name="hora_final"
                          isInvalid={errorsAddHorario.hora_final}
                          disabled={!values.codigo}
                          onChange={handleChange}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errorsAddHorario.hora_final}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group>
                        <Form.Label>Dias da Semana</Form.Label>
                        <Select
                          isMulti
                          name="dias_semana"
                          options={diasDaSemanaOptions}
                          value={values.dias_semana}
                          closeMenuOnSelect={false}
                          isDisabled={!values.codigo}
                          onChange={(selected) => {
                            log.debug(
                              "Opções de dias da semana: " +
                                JSON.stringify(selected)
                            );
                            setFieldValue("dias_semana", selected);
                          }}
                          style={{
                            flex: "1 0 0",
                          }}
                        />
                        {errorsAddHorario.dias_semana && (
                          <small className="form-text text-danger">
                            {errorsAddHorario.dias_semana}
                          </small>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        variant="outline-primary"
                        className="float-right"
                        onClick={adicionarHorario}
                        disabled={!values.codigo}
                      >
                        Adicionar Horário
                      </Button>
                    </Col>
                  </Row>

                  <div className="bg-light p-2 mt-2">
                    {values.horarios.length === 0 && (
                      <>
                        <p className="text-danger">Nenhum horário cadastrado</p>
                        <small>
                          Adicione um horário de trabalho acima, definindo os
                          horários de entrada e saída e escolhendo os dias da
                          semana aplicáveis.
                        </small>
                      </>
                    )}
                    {values.horarios.map((h, index) => {
                      return (
                        <div
                          key={index}
                          className="d-sm-flex justify-content-between align-items-baseline"
                        >
                          <span>
                            {h.hora_inicial} - {h.hora_final}
                          </span>
                          <span>
                            {h.dias_semana.map((dia, index) => {
                              const sep =
                                index < h.dias_semana.length - 1 ? ", " : "";
                              return `${diasDaSemanaById[dia]}${sep}`;
                            })}
                          </span>
                          <Button
                            variant="link"
                            onClick={() => excluirHorario(index)}
                          >
                            x
                          </Button>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {submitError && <Alert variant="danger">{submitError}</Alert>}
              </Modal.Body>
              <Modal.Footer>
                {errors.horarios && (
                  <span className="text-danger">{errors.horarios}</span>
                )}
                <Button
                  variant="primary"
                  type="submit"
                  className="mb-1"
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                >
                  Salvar
                </Button>
                <Button
                  variant="outline-primary"
                  disabled={isSubmitting}
                  onClick={() => {
                    resetForm();
                    onCancel();
                  }}
                >
                  Cancelar
                </Button>
              </Modal.Footer>
            </>
          );
        }}
      </Formik>
    );
  };

  if (!atendente) return null;

  return (
    <GenericModal
      show={show}
      title={
        atendente.codigo
          ? "Editando Configuração de Atendente"
          : "Nova Configuração de Atendente"
      }
      onCancel={onCancel}
      content={renderModalBody()}
      outsideModalBody
    />
  );
};

export default ModalEdicaoAtendente;
