import React from "react";
import { FaHeadset, FaCalendarAlt } from "react-icons/fa";
import { RiUserSettingsLine, RiListSettingsLine } from "react-icons/ri";
import { IoIosPaper } from "react-icons/io";
import trabalhos from "~/Images/trabalhos.svg";
import workflow from "~/Images/workflow.svg";
import AuthStorage from "~/Utils/AuthStorage";
import { caracteristicas } from "~/constants";

const links = [
  {
    icon: <img src={trabalhos} alt="Negócios" />,
    label: "Negócios",
    dropdown: [
      {
        href: "/negocios/caixa-aprovacao",
        icon: <img src={workflow} alt="Caixa de Aprovação" />,
        label: "Caixa de Aprovação",
      },
    ],
  },
  {
    icon: <FaHeadset />,
    label: "Atendimento",
    isNew: true,
    visible: () =>
      AuthStorage.temCaracteristicas([
        caracteristicas.ConfiguraAtendimento,
        caracteristicas.RealizaAgendamento,
      ]),
    dropdown: [
      {
        href: "/atendimento/agendamento",
        icon: <FaCalendarAlt />,
        label: "Agendamento",
        visible: () =>
          AuthStorage.temCaracteristica(caracteristicas.RealizaAgendamento),
      },
      {
        href: "/atendimento/configuracao/atendentes",
        icon: <RiUserSettingsLine />,
        label: "Configuração de Atendentes",
        visible: () =>
          AuthStorage.temCaracteristica(caracteristicas.ConfiguraAtendimento),
      },
      {
        href: "/atendimento/configuracao/servicos",
        icon: <RiListSettingsLine />,
        label: "Configuração de Servicos",
        visible: () =>
          AuthStorage.temCaracteristica(caracteristicas.ConfiguraAtendimento),
      },
      {
        href: "/atendimento/agendamento/relatorio",
        icon: <IoIosPaper />,
        label: "Relatório de Agendamentos",
        visible: () =>
          AuthStorage.temCaracteristicas([
            caracteristicas.ConfiguraAtendimento,
            caracteristicas.RealizaAgendamento,
          ]),
      },
    ],
  },
];

const getLinks = () =>
  links
    .map((link) => {
      if (!link.dropdown) return link;

      return {
        ...link,
        dropdown: link.dropdown.filter(
          (drop) => !drop.visible || (drop.visible && drop.visible())
        ),
      };
    })
    .filter((link) => !link.visible || (link.visible && link.visible()));

export default getLinks;
