import React from "react";
import { FaUser } from "react-icons/fa";

import format from "~/Utils/format";

const OcorrenciaTemplate = ({ ocorrencia }) => (
  <div>
    <div className="d-flex justify-content-between">
      <small>{format.datetime(ocorrencia.data)}</small>
      <small className="text-right" title={ocorrencia.usuario.nome_usuario}>
        <FaUser className="mr-1" />
        {ocorrencia.usuario.nome}
      </small>
    </div>
    <h5 className="text-uppercase">{ocorrencia.titulo}</h5>
    <p>{ocorrencia.texto}</p>
    <hr />
  </div>
);

export default OcorrenciaTemplate;
