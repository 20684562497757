import React from "react";
import { Pagination } from "react-bootstrap";

const Paginator = ({
  onGotoPage,
  onPreviousPage,
  onNextPage,
  pageIndex,
  totalPages,
}) => {
  const canGoBack = pageIndex > 0;
  const canGoForward = pageIndex < totalPages - 1;
  return (
    <Pagination>
      <Pagination.First
        onClick={() => onGotoPage(0)}
        disabled={pageIndex === 0}
      />
      <Pagination.Prev onClick={() => onPreviousPage()} disabled={!canGoBack} />
      {pageIndex > 0 && (
        <>
          <Pagination.Item onClick={() => onGotoPage(0)}>1</Pagination.Item>
          {pageIndex - 2 > 1 && <Pagination.Ellipsis />}
        </>
      )}
      {pageIndex > 2 && (
        <Pagination.Item onClick={() => onGotoPage(pageIndex - 2)}>
          {pageIndex - 1}
        </Pagination.Item>
      )}

      {pageIndex > 1 && (
        <Pagination.Item onClick={() => onGotoPage(pageIndex - 1)}>
          {pageIndex}
        </Pagination.Item>
      )}

      <Pagination.Item active>{pageIndex + 1}</Pagination.Item>

      {pageIndex < totalPages - 2 && (
        <Pagination.Item onClick={() => onGotoPage(pageIndex + 1)}>
          {pageIndex + 2}
        </Pagination.Item>
      )}
      {pageIndex < totalPages - 3 && (
        <Pagination.Item onClick={() => onGotoPage(pageIndex + 2)}>
          {pageIndex + 3}
        </Pagination.Item>
      )}
      {pageIndex < totalPages - 1 && (
        <>
          {pageIndex + 4 < totalPages && <Pagination.Ellipsis />}
          <Pagination.Item onClick={() => onGotoPage(totalPages - 1)}>
            {totalPages}
          </Pagination.Item>
        </>
      )}

      <Pagination.Next onClick={() => onNextPage()} disabled={!canGoForward} />
      <Pagination.Last
        onClick={() => onGotoPage(totalPages - 1)}
        disabled={pageIndex === totalPages - 1}
      />
    </Pagination>
  );
};

export default Paginator;
