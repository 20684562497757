import styled from "styled-components";

export const LoadingContainer = styled.div`
  margin: 30px auto;
  max-width: 200px;

  .spinner {
    animation: spin 2s infinite linear;
    font-size: 25px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
`;
