import React from "react";

import Navbar from "~/Components/Navbar";

import { MainContainer, MainContent, Content } from "./styles";

const PageContainer = ({ children }) => {
  return (
    <MainContainer>
      <Navbar />
      <Content>
        <MainContent>{children}</MainContent>
      </Content>
    </MainContainer>
  );
};

export default PageContainer;
