import LocalStorage from "./LocalStorage";

const TOKEN = "TOKEN";
const USER = "USER";
const CARACTERISTICAS = "CARACTERISTICAS";

const AuthStorage = {
  setUser: (user) => {
    LocalStorage.set(TOKEN, user.access_token);
    LocalStorage.set(USER, user);
  },

  getUser: () => {
    return LocalStorage.get(USER);
  },

  setCaracteristicas: (caracteristicas) => {
    LocalStorage.set(CARACTERISTICAS, caracteristicas);
  },
  getCaracteristicas: () => {
    return LocalStorage.get(CARACTERISTICAS) || [];
  },
  temCaracteristica: (codigo) => {
    return AuthStorage.getCaracteristicas().includes(codigo);
  },
  temCaracteristicas: (permitidos, rejeitados = []) => {
    const caracteristicas = AuthStorage.getCaracteristicas();
    if (caracteristicas.some((r) => rejeitados.includes(r))) return false;
    return (
      permitidos.length === 0 ||
      caracteristicas.some((r) => permitidos.includes(r))
    );
  },

  clear: () => {
    LocalStorage.remove(TOKEN);
    LocalStorage.remove(USER);
    LocalStorage.remove(CARACTERISTICAS);
  },

  getToken() {
    return LocalStorage.get(TOKEN);
  },

  isLogged() {
    return !!this.getToken();
  },
};

export default AuthStorage;
